<script>
  import {
    alert,
    authed,
    collective,
    connectionSend,
    currentDoc,
    dateOptions,
    getUrl,
    projectStore,
    readonly,
    router,
    t,
    viewport,
    waitForUpdates,
  } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { fade } from '@dabble/toolkit/transitions';
  import { mdiClose, mdiLoading } from '@mdi/js';
  import { format, formatDistance, formatDistanceToNow } from 'date-fns';
  import capitalize from 'lodash/capitalize';
  import { onDestroy, onMount, tick } from 'svelte';
  import { showHistory } from '../versioning';

  $: changesCap = parseInt(localStorage.getItem('changesCap')) || 1000;
  const CUTOFF = 3600000; // 1 hour
  let sparse;
  let historicalChanges;
  let historicalSnapshots;
  let someChangesLoaded;
  let firstVersion;
  let lastVersion;
  let fullHistory;
  let hoveredSession;
  let selectedSession;
  let change;
  let realVersion;
  let currentVersion;
  let loading;
  let navigateOnChange = true;
  let lastestSnapshotVersion;
  let earliestVersion;

  $: readonly.toggleLock('history', $projectStore.currentVersion !== null);
  $: connected = $authed;
  $: someChangesLoaded = (historicalChanges && historicalChanges.length) || 0;
  $: lastVersion = someChangesLoaded && historicalChanges[historicalChanges.length - 1].version;
  $: totalVersions = lastVersion - earliestVersion;
  $: fullHistory = someChangesLoaded && historicalChanges[0].version <= earliestVersion;
  $: sessions = splitSessions(historicalChanges);
  $: displayedSession = hoveredSession || selectedSession;
  $: if (connected && !fullHistory) load();
  $: sparse =
    historicalChanges &&
    historicalChanges.length &&
    historicalChanges[historicalChanges.length - 1].version !== historicalChanges.length - 1;
  $: changeType = getChangeType(change);

  onMount(load);
  onDestroy(unload);

  async function load() {
    if (!lastestSnapshotVersion && !$projectStore.project.currentVersion) {
      lastestSnapshotVersion = Math.floor($projectStore.project.version / 200) * 200;
      earliestVersion = Math.max(0, lastestSnapshotVersion - changesCap) || 0;
    }

    // Already loaded or loading
    if (firstVersion <= earliestVersion || loading) return;

    loading = true;

    const options = { after: earliestVersion - 1 };
    const { projectId } = $projectStore;
    window.historicalChanges = historicalChanges = await collective.store.getChanges(projectId, options);
    if (!historicalChanges.length) {
      const snapshot = await collective.store.getSnapshot(projectId, { ...options, reverse: true });
      if (!snapshot) {
        console.error('No changes found for project', projectId);
        loading = false;
        return;
      }

      firstVersion = currentVersion = snapshot.version;
    } else {
      firstVersion = historicalChanges[0].version;
      currentVersion = historicalChanges[historicalChanges.length - 1].version;
    }

    if (firstVersion <= earliestVersion || !connected) {
      window.historicalSnapshots = historicalSnapshots = await collective.store.getSnapshots(projectId, options);

      if (
        !historicalSnapshots.length ||
        (historicalSnapshots[0].version !== earliestVersion && earliestVersion === 0)
      ) {
        await collective.backfillSnapshots(projectId, [historicalChanges[0]], null);
        window.historicalSnapshots = historicalSnapshots = await collective.store.getSnapshots(projectId, options);
      }

      if (historicalSnapshots.length && historicalSnapshots[0].version === earliestVersion) {
        gotoVersion(lastVersion);
        loading = false;
        return;
      }
    }

    const earliestSnapshot =
      (
        await connectionSend('getDocs', 'project_snapshots', [
          ['id', '==', projectId],
          ['version', '<=', earliestVersion],
          ['orderBy', 'version', 'desc'],
          ['limit', 1],
        ])
      )[0] || null;

    earliestVersion = earliestSnapshot ? earliestSnapshot.version : 0;

    // Will never be true now, because we'll go all the way back to the beginning of no snapshot is found
    // if (!earliestSnapshot && earliestVersion !== 0) {
    //   loading = false;
    //   alert($t('alert_header_backfill_failed'), $t('alert_backfill_failed_1', { version: earliestVersion }));
    //   return;
    // }

    if (earliestSnapshot) {
      await collective.store.putSnapshot(earliestSnapshot);
    }

    const wheres = [
      ['projectId', '==', projectId],
      ['version', '<', historicalChanges.length ? firstVersion : firstVersion + 1],
      ['version', '>=', earliestVersion],
      ['orderBy', 'version'],
    ];

    window.historicalChanges = historicalChanges = await connectionSend('getDocs', 'project_changes', wheres);

    if (historicalChanges[0].version !== earliestVersion) {
      loading = false;
      alert(
        $t('alert_header_backfill_failed'),
        $t('alert_backfill_failed_2', { version: historicalChanges[0].version })
      );
      return;
    }

    // TODO test project hjPAsYhY3hgewM0n to see how Dabble can work with sparsely populated projects
    // await collective.backfillProject(projectId, changes);
    collective.backfillProject(projectId, historicalChanges, earliestSnapshot);
    window.historicalSnapshots = historicalSnapshots = await collective.store.getSnapshots(projectId);
    lastVersion = historicalChanges[historicalChanges.length - 1].version;
    gotoVersion(lastVersion);

    firstVersion = 0;
    loading = false;
  }

  function unload() {
    if (!$projectStore.currentVersion !== null) {
      projectStore.reload();
      readonly.removeLock('history');
    }
  }

  function splitSessions(changes) {
    const sessions = [];
    if (!changes) return sessions;

    const byCreated = changes.slice(); //.sort((a, b) => a.created - b.created);
    byCreated.forEach((change, i) => {
      const prev = byCreated[i - 1];
      const session =
        prev && prev.created >= change.created - CUTOFF
          ? sessions[sessions.length - 1]
          : (sessions[sessions.length] = {
              startDate: new Date(change.created),
              startVersion: change.version,
              endDate: new Date(change.created),
              endVersion: change.version,
            });

      session.endDate = new Date(change.created);
      // If the "created" is out of order with the version, we want the last version of the session. It is possible
      // that some sessions overlap in their changes if a created date is incorrect. This is fine.
      session.endVersion = Math.max(change.version, session.endVersion);
    });
    // length in seconds with 1 second being the minimum
    // sessions.forEach(session => session.length = Math.max(Math.round((session.endDate - session.startDate)/1000), 1));
    sessions.forEach((session, i) => {
      session.index = i;
      session.length = session.endVersion - session.startVersion + 1;
    });

    return sessions;
  }

  function onMouseEnter(session) {
    currentVersion = session.startVersion;
    hoveredSession = session;
  }

  function onMouseExit() {
    currentVersion = realVersion;
    hoveredSession = null;
  }

  function gotoSession(session) {
    if (session.startVersion === realVersion) return;
    realVersion = currentVersion = session.startVersion;
    change = getChange(currentVersion);
    selectedSession = session;
    projectStore.goto(session.startVersion, historicalSnapshots, historicalChanges);
    focusChange();
  }

  function onMove(version) {
    return gotoVersion(version);
    // currentVersion = Math.max(version, firstVersion);
    // change = getChange(currentVersion);
    // hoveredSession = sessions.find(s => s.startVersion <= change.version && s.endVersion >= change.version);
  }

  function gotoVersion(version) {
    if (version === realVersion) return;
    realVersion = currentVersion = Math.max(version, firstVersion);
    change = getChange(currentVersion);
    if (!change) return setTimeout(() => gotoVersion(version), 100);
    selectedSession = sessions.find(s => s.startVersion <= change.version && s.endVersion >= change.version);
    hoveredSession = null;
    projectStore.goto(change.version, historicalSnapshots, historicalChanges);
    focusChange();
  }

  function getChange(version) {
    return historicalChanges.find(change => change.version === version);
  }

  async function focusChange() {
    await tick();
    if (!$projectStore.change) return;
    if (!$projectStore.change.ops[0] || $projectStore.change.ops[0].op !== '@changeText') return;
    const docId = $projectStore.change.ops[0].path.split('/')[2];
    if (navigateOnChange && (!$currentDoc || ($currentDoc.type !== 'novel_plot' && $currentDoc.id !== docId))) {
      router.navigate(getUrl(docId));
      await waitForUpdates();
    }
    const container = viewport.container.get();
    const first = container && container.querySelector('.added-text, .deleted-text, .formatted-text');
    if (first) {
      if (first.scrollIntoViewIfNeeded) first.scrollIntoViewIfNeeded({ block: 'center' });
      else first.scrollIntoView({ block: 'center' });
    }
  }

  function close() {
    projectStore.reload();
    $showHistory = false;
  }

  function getChangeType(change) {
    if (!change) return 'None';
    const textChanges = change.ops.filter(op => op.op === '@changeText');
    if (textChanges.length > 1) return 'Global find-replace';
    if (textChanges.length === 1) {
      const op = textChanges[0];
      const [, , docId, field] = op.path.split('/');
      return `${capitalize(field)} text change for ${docId}`;
    }
    if (change.ops.length === 1) {
      const op = change.ops[0];
      const [, , docId, field] = op.path.split('/');
      if (field === 'grid') return `Plot grid change for ${docId}`;
      if (field && op.op === 'add' && typeof op.value === 'string') return `${capitalize(field)} change for ${docId}`;
    }
    return 'Project structure change';
  }
</script>

<div class="history-slider">
  {#if displayedSession}
    <div>
      <strong>Session:</strong>
      <span use:tooltipTop={format(displayedSession.startDate, 'PPp')}
        >{formatDistanceToNow(displayedSession.startDate, { ...dateOptions, addSuffix: true })}</span
      >
      -
      {formatDistance(displayedSession.endDate, displayedSession.startDate, dateOptions)},
      {displayedSession.endVersion - displayedSession.startVersion} changes,
      {displayedSession.startVersion}-{displayedSession.endVersion}
    </div>
  {/if}
  {#if change}
    <div>
      <strong>Version:</strong>
      {change.version},
      {changeType},
      <span use:tooltipTop={format(change.created, 'PPp')}
        >{formatDistanceToNow(change.created, { ...dateOptions, addSuffix: true })}</span
      >
    </div>
  {/if}
  {#if sessions}
    <div class="sessions">
      {#each sessions as session}
        <button
          data-length={session.length}
          data-total={totalVersions}
          class="btn primary session"
          class:active={session === selectedSession}
          style="width:{(session.length / totalVersions) * 100}%"
          on:mouseenter={() => onMouseEnter(session)}
          on:mouseleave={() => onMouseExit(session)}
          on:click={() => gotoSession(session)}
          disabled={session.startVersion < firstVersion}
        />
      {/each}
    </div>
  {/if}
  <input
    class="range"
    type="range"
    min={earliestVersion}
    max={lastVersion || 0}
    on:input={function () {
      onMove(this.value);
    }}
    on:change={function () {
      gotoVersion(this.value);
    }}
    value={currentVersion}
  />
  {#if !fullHistory && historicalChanges && historicalChanges.length && !connected}
    <small class="form-text text-muted">
      You don't have the full project history downloaded on this computer. You will only be able to view the history you
      have, starting from {formatDistanceToNow(historicalChanges[0].created, { ...dateOptions, addSuffix: true })}. To
      get the full history you must connect to the internet.
    </small>
  {/if}
  <button class="icon display-icon-close" on:click={close}>
    <Icon path={mdiClose} />
  </button>
  {#if loading}
    <div class="loading" transition:fade><span class="text">{$t('loading')}</span> <Icon path={mdiLoading} spin /></div>
  {:else}
    <label class="navigate-toggle">Navigate to Change? <input type="checkbox" bind:checked={navigateOnChange} /></label>
  {/if}
  <small>CAP: {changesCap}</small>
</div>

<style>
  .history-slider {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border-radius: 2px;
    background: var(--page-background);
    box-shadow: var(--modal-shadow);
    padding: 20px 40px;
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .icon.display-icon-close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .loading,
  .navigate-toggle {
    position: absolute;
    color: var(--text-color-lighter);
    top: 4px;
    right: 36px;
  }
  .loading .text {
    font-size: var(--font-size-xs);
    text-transform: uppercase;
  }
  .range {
    width: 100%;
  }
  .sessions {
    display: flex;
    justify-content: flex-end;
  }
  .session {
    /* min-width: 8px; */
    height: 40px;
    margin-right: 1px;
    padding: 0;
    border-radius: 2px;
    transition: width 0.15s ease-in-out;
  }
</style>
