<svelte:options accessors />

<script lang="ts">
  import { alert, t } from '@dabble/app';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { ignoreNextAutoscroll } from '@dabble/toolkit/autoscroll';
  import { mdiBookAlphabet, mdiDelete } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { Editor, EditorRange, diff, getIndexFromNode, getNodeLength } from 'typewriter-editor';
  import { addToDictionary } from '../grammar-api';
  import { FormatIssue } from '../grammar-types';

  const TIMEOUT = 300;
  const dispatch = createEventDispatcher();

  export let issueId: string;
  export let issue: FormatIssue;
  export let editor: Editor;

  let allDiffs: diff.Diff[][];
  let showDiffs: boolean;
  let timeout: ReturnType<typeof setTimeout>;
  const mark = editor.root.querySelector(`format-grammar[data-id="${issueId}"]`);

  $: allDiffs = getAllDiffs(issue);
  $: showDiffs = allDiffs && allDiffs.every(diffs => diffs.filter(op => op[0] !== diff.EQUAL).length === 1);

  function getAllDiffs(issue: FormatIssue) {
    const { suggestions, category } = issue;
    const range = getRange();
    if (!range) return;
    const text = editor.doc.getText(range);
    if (!text || !suggestions || category === 'spelling') return;
    return suggestions.map(suggestion => {
      if (suggestion === '(omit)') suggestion = '';
      let diffs = diff(text, suggestion);
      if (diffs.some(op => op[0] === diff.INSERT)) {
        diffs = diffs.filter(op => op[0] !== diff.DELETE);
      }
      return diffs;
    });
  }

  function showSuggestion(diffs: diff.Diff[]) {
    return diffs
      .map(([op, text]) => {
        if (op === diff.EQUAL) return escape(text);
        return `<span class="${op === diff.INSERT ? 'add' : 'remove'}">${escape(text)}</span>`;
      })
      .join('');
  }

  function getRange() {
    const marks = editor.root.querySelectorAll(`format-grammar[data-id="${issueId}"]`);
    if (marks.length === 0) return;
    const start = getIndexFromNode(editor, marks[0]);
    let end = start;
    for (const mark of marks) {
      end += getNodeLength(editor, mark);
    }
    return [start, end] as EditorRange;
  }

  function useSuggestion(suggestion: string) {
    if (!editor) return;
    if (suggestion === '(omit)') suggestion = '';
    const range = getRange();
    ignoreNextAutoscroll();
    const format = editor.doc.getTextFormat(range);
    delete format.grammar;
    editor.select(range);
    editor.change
      .delete(range)
      .insert(range[0], suggestion, format)
      .select([range[0], range[0] + suggestion.length])
      .apply();
    if (editor.root.ownerDocument !== document) {
      editor.root.focus();
    }
    close();
  }

  async function addWordToDictionary() {
    try {
      await addToDictionary(issue.word);
      close();
    } catch (err) {
      await alert($t('alert_header_dictionary_error'), err.message);
    }
    editor.root?.focus();
  }

  function dismiss() {
    close();
    const range = getRange();
    const line = editor.doc.getLineAt(range[0]);
    editor.formatLine({ ...line.attributes, grammar: { issues: { [issueId]: { dismissed: true } } } }, range);
    if (editor.root.ownerDocument !== document) {
      editor.root.focus();
    }
  }

  export function close() {
    clearTimeout(timeout);
    dispatch('close');
  }

  export function onMouseEnter() {
    clearTimeout(timeout);
  }

  export function onMouseLeave() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      close();
    }, TIMEOUT);
  }

  const div = document.createElement('div');

  function escape(text: string) {
    div.textContent = text;
    return div.innerHTML;
  }
</script>

<Dropdown
  target={mark}
  exposed
  noFocus
  placement="bottom-start"
  ignoreClicks
  on:close={close}
  on:mouseenter={onMouseEnter}
  on:mouseleave={onMouseLeave}
>
  <div class="issue">
    {#if !issue.suggestions || !issue.suggestions.length}<div class="hint">{issue.hint}</div>{/if}
    {#if issue.suggestions}
      {#each issue.suggestions as suggestion, i}
        <button class="suggestion" on:click={() => useSuggestion(suggestion)}>
          {#if !i}<div class="hint">{issue.hint}</div>{/if}
          <span class="arrow">→</span>
          {#if showDiffs}
            {@html showSuggestion(allDiffs[i])}
          {:else}
            {suggestion}
          {/if}
        </button>
      {/each}
    {/if}
    <hr />
    {#if issue.category === 'spelling'}
      <button class="dropdown-item" on:click={addWordToDictionary}>
        <Icon path={mdiBookAlphabet} />
        {$t('grammar_dictionary_add')}
      </button>
    {/if}
    <button class="dropdown-item" on:click={dismiss}><Icon path={mdiDelete} /> {$t('grammar_dismiss')}</button>
  </div>
</Dropdown>

<style>
  .issue {
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }
  .suggestion:first-child {
    border-radius: 4px 4px 0 0;
  }
  .suggestion:last-child {
    border-radius: 0 0 4px 4px;
  }
  .hint {
    padding: 8px 16px;
    color: var(--text-color-light);
    font-size: var(--font-size-lg);
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  .suggestion {
    padding: 8px 16px;
    color: var(--text-color-normal);
    font-size: var(--font-size-lg);
    cursor: pointer;
    margin: 0 -4px;
    overflow-wrap: break-word;
    word-break: break-word;
    border: none;
    background: none;
    text-align: left;
  }
  .suggestion .hint {
    padding: 0 0 8px;
    color: var(--text-color-lighterer);
    font-size: var(--font-size-xs);
    text-align: left;
  }
  .suggestion :global(.add) {
    color: var(--green);
  }
  .suggestion :global(.remove) {
    position: relative;
    color: var(--dabble-red);
    background: linear-gradient(
      to left top,
      transparent calc(50% - 1px),
      currentColor calc(50%),
      transparent calc(50% + 1px)
    );
  }
  .suggestion .arrow {
    color: var(--text-color-lightest);
    font-size: 14px;
  }
  .suggestion:hover,
  .suggestion:hover .hint,
  .suggestion:hover .arrow,
  .suggestion:hover :global(.add),
  .suggestion:hover :global(.remove) {
    background: var(--dabble-blue);
    color: var(--white);
  }
  .suggestion:hover :global(.remove) {
    background: linear-gradient(
      to left top,
      transparent calc(50% - 1px),
      currentColor calc(50%),
      transparent calc(50% + 1px)
    );
  }
  .dropdown-item {
    color: var(--text-color-lighter);
  }
</style>
