import { writable } from './stores/store';

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

export const touchEnabled = 'ontouchstart' in window;
// iPad tries to look exactly the same as mac :( the only difference is touch support (for now)
export const isIPad = navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
export const isIOS = isIPad || (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream);
export const isAndroid = !isIOS && /Mobi|Android/.test(navigator.userAgent) && !(window as any).MSStream;
export const isMobileMode = isIOS || isAndroid;
export const isMac = navigator.userAgent.indexOf('Macintosh') !== -1;
export const isApple = isMac || isIOS;

export const isIEedge = navigator.userAgent.indexOf('Edg') > -1;
export const isIOSChrome = navigator.userAgent.match('CriOS');

export const standalone = (navigator as any).standalone || false;
export const size = writable<'mobile' | 'tablet' | 'desktop'>('desktop');

const tabletQuery = window.matchMedia('(min-width: 600.02px)');
const desktopQuery = window.matchMedia('(min-width: 1020.02px)');
const update = () => {
  size.set(desktopQuery.matches ? 'desktop' : tabletQuery.matches ? 'tablet' : 'mobile');
};
update();

tabletQuery.addEventListener('change', update);
desktopQuery.addEventListener('change', update);
