import { waitForUpdates } from '@dabble/app';
import getFindExpression from '@dabble/find/find-expression';
import { tick } from 'svelte';
import { Delta, EditorRange, TextDocument, isEqual } from 'typewriter-editor';
import { getFindMap } from '../../find/find-map';
import { observe } from '../observe';
import { Doc, DocSettings } from '../types';
import { GetURLFunction } from '../urls';
import { DocStore } from './doc';
import { ProjectStore } from './project';
import { RouterStore } from './router';
import { SettingsStore } from './settings';
import { Readable, Stores, derived, writable } from './store';
import { Viewport } from './viewport';

export type Ranges = EditorRange[];
export type FieldMap = { [field: string]: Ranges };
export type DocMap = { [id: string]: FieldMap };

export interface FindState {
  open: boolean;
  text: string;
  replaceText: string;
  replaceOpen: boolean;
  inProject: boolean;
  matchWholeWords: boolean;
  matchCase: boolean;
  matchRegex: boolean;
  map: DocMap;
  mapAll: DocMap;
  selected: Match;
}

export interface FindStateStore extends Readable<FindState> {
  open(): void;
  close(): void;
  openInProject(): void;
  openReplace(): void;
  useCurrentWordForFind(): void;
  findNext(firstFind?: boolean): void;
  findPrevious(firstFind?: boolean): void;
  replace(): void;
  replaceAll(): void;
  update(data?: Partial<FindState>): void;
}

interface Match {
  id: string;
  field: string;
  range: EditorRange;
}

const INPUT_FIND = 'findInput';
const INPUT_REPLACE = 'replaceInput';

const emptyState: FindState = {
  open: false,
  text: '',
  replaceText: '',
  replaceOpen: false,
  inProject: false,
  matchWholeWords: false,
  matchCase: false,
  matchRegex: false,
  map: null,
  mapAll: null,
  selected: null,
};

export function createFindReplaceStore(
  settings: SettingsStore,
  projectStore: ProjectStore,
  currentDoc: DocStore,
  router: RouterStore,
  getUrl: GetURLFunction,
  viewport: Viewport
): FindStateStore {
  let state: FindState = emptyState;
  let lastSelection: Match = null;
  observe(viewport.selection, selection => {
    if (selection) {
      lastSelection = { ...selection.field, range: selection.range };
    }
  });

  const findState = writable(state);
  const docId = derived(currentDoc, doc => doc && doc.id);
  const matchRegex = derived(findState, state => state.matchRegex);
  const docSettings = derived([settings, currentDoc], ([settings, doc]) => doc && (settings[doc.type] as DocSettings));
  const isFinding = derived(findState, state => Boolean(state.open && state.text));

  observe(findState, updateMap);
  observe(isFinding, async finding => {
    if (!projectStore.get().project) return close();
    await tick(); // Allow the findView to update first
    if (finding) {
      if (!state.selected) {
        findNext(true);
      }
    } else if (state.selected) {
      update({ selected: null });
    }
  });

  let queued = false;
  async function update(data?: Partial<FindState>) {
    // A request to update the map without any changes in find state (because text changed in the project)
    if (!data) {
      if (state.open) updateMap();
      return;
    }
    const keys = Object.keys(data) as (keyof FindState)[];
    if (keys.every(key => isEqual(state[key], data[key]))) return;
    state = { ...state, ...data };
    // Don't churn the UI with too many conccurent updates
    if (queued) return;
    queued = true;
    await tick();
    queued = false;
    findState.set(state);
  }

  async function open() {
    update({ open: true });
    await tick();
    focus(INPUT_FIND);
  }

  function close() {
    if (!state.open) return;
    update({ open: false });
  }

  function openInProject() {
    update({ open: true, inProject: true });
  }

  function openReplace() {
    const text = getFindText();
    update({ open: true, replaceOpen: true, text });
    focus(INPUT_REPLACE);
  }

  function useCurrentWordForFind() {
    const text = getFindText();
    update({ open: true, text });
    focus(INPUT_FIND);
  }

  function findNext(firstFind?: boolean) {
    firstFind = getIsFirst(firstFind);
    findNextMatch(false, firstFind);
  }

  function findPrevious(firstFind?: boolean) {
    firstFind = getIsFirst(firstFind);
    findNextMatch(true, firstFind);
  }

  async function replace() {
    const { replaceText, matchRegex, map } = state;
    if (!map) return;

    // find the current field/range and replace the next text
    const match = state.selected;
    const editor = match && (await viewport.getEditor(match));
    if (match && editor) {
      const { id, field, range: selection } = match;
      map[id][field].some(range => {
        if (selection[0] !== range[0] || selection[1] !== range[1]) return;
        const change = editor.change.delete(range);
        if (matchRegex) {
          const expr = getFindExpression(state);
          const text = editor.doc.getText(range);
          try {
            const replace = text.replace(expr, replaceText);
            change.insert(range[0], replace);
          } catch (e) {}
        } else {
          change.insert(range[0], replaceText);
        }
        editor.update(change);
        projectStore.saveText(projectStore.getQueueKey(id, field));
        return true;
      });
    }
    findNextMatch(false);
    await update();
  }

  async function replaceAll() {
    const { replaceText, mapAll } = state;
    const { docs } = projectStore.get();
    if (!mapAll) return;

    const patch = projectStore.patch();

    Object.keys(mapAll).forEach(docId => {
      Object.keys(mapAll[docId]).forEach(field => {
        const content = docs[docId] && (docs[docId][field] as TextDocument | string);
        if (typeof content === 'string') {
          // Text field
          const expr = getFindExpression(state);
          try {
            patch.updateDoc(docId, { [field]: content.replace(expr, replaceText) });
          } catch (e) {}
        } else {
          // Rich Text field
          const matches = mapAll[docId][field];
          const delta = getDeltaFromIndexes(content, matches);
          if (delta) patch.changeText(docId, field, delta);
        }
      });
    });

    await patch.save();

    projectStore.forceTextUpdate();
    await update();
  }

  function getIsFirst(firstFind?: boolean) {
    if (typeof firstFind !== 'boolean') firstFind = false;
    if (state.open) return false || firstFind;
    // Get the currently selected text from the currently selected field
    const text = state.text || viewport.getSelectedText();
    update({ open: true, text });
    return true;
  }

  function getFindText() {
    // Get the currently selected text or the find text
    return viewport.getSelectedText() || state.text;
  }

  async function focus(id: string, focusOnly?: boolean) {
    await tick();
    const input = document.getElementById(id) as HTMLInputElement;
    if (input) {
      const [, editor] = viewport.getSelection();
      editor?.select(null);
      input.focus();
      if (!focusOnly) input.select();
    }
  }

  function updateMap() {
    if (!projectStore.get().project) return close();
    if (state.open && state.text) {
      const [map, mapAll] = getFindMap(state, docSettings.get(), projectStore.get(), currentDoc.get());
      update({ map, mapAll });
    } else {
      update({ map: null, mapAll: null });
    }
  }

  function findNextMatch(isPrev = false, findFirst?: boolean, wrap?: boolean): Promise<void> {
    const { findSettings } = docSettings.get();
    const { map, inProject } = state;

    if (!map || !Object.keys(map).length) {
      return;
    }

    const matches = (!findSettings || !findSettings.skip) && getDocMatches();
    const match = matches && getNextMatch(matches, inProject && !wrap, isPrev);

    if (!match) {
      if (inProject) {
        const doc = getNextDoc(isPrev);
        const url = doc && getUrl(doc, projectStore.get().projectId);
        if (!url) {
          // Wrap around
          findNextMatch(isPrev, findFirst, true);
        } else {
          viewport.skipNextRestore();
          router.navigate(url);
          update({ selected: null });
          waitForChange(docId).then(() => findNextMatch(isPrev));
        }
      }
    } else {
      update({ selected: match });
      lastSelection = { ...match, range: [match.range[0], match.range[0]] };
      if (!findFirst && viewport.getSelection()[1]) {
        viewport.select(match, match.range);
      } else {
        viewport.scrollIntoView(match, match.range[0]);
      }
    }
  }

  function waitForChange<S extends Stores>(stores: S) {
    return new Promise(resolve => {
      let first = true;
      const unsubscribe = observe<S>(stores, async value => {
        if (first) return (first = false);
        unsubscribe();
        await waitForUpdates();
        resolve(value);
      });
    });
  }

  function getDocMatches() {
    const matches: Match[] = [];
    const { map } = state;
    if (!map) return matches;

    const fields = viewport.getFields();

    fields.forEach(({ id, field }) => {
      if (map[id] && map[id][field]) {
        map[id][field].forEach(range => matches.push({ id, field, range }));
      } else {
        // Add a blank entry for fields without a match so that we can find "next" matches when the selection is there
        matches.push({ id, field, range: null });
      }
    });

    return matches;
  }

  // Get next/prev searchable doc to jump to when doing a findInProject
  function getNextDoc(isPrev = false) {
    const list = getDocSearchList(currentDoc.get());
    if (list.length === 1) return null;
    const index = list.indexOf(currentDoc.get());
    return isPrev ? list[index - 1] || list[list.length - 1] : list[index + 1] || list[0];
  }

  function getCurrentLocation() {
    let [{ id, field }, , range] = viewport.getSelection();
    return (range && { id, field, range }) || findState.get().selected || getValidLastSelection();
  }

  function getNextMatch(matches: Match[], findInProject: boolean, isPrev = false) {
    const currentMatch = getCurrentLocation();

    let gt = (a: number, b: number) => a > b;
    let gte = (a: number, b: number) => a >= b;

    if (isPrev) {
      matches = matches.slice().reverse();
      gt = (a, b) => a < b;
      gte = (a, b) => a <= b;
    }

    const firstMatch = matches.find(match => match.range);
    if (!currentMatch) return firstMatch;

    const activeIndex = matches.findIndex(match => match.id === currentMatch.id && match.field === currentMatch.field);

    // Reorder matches to put the first of the current field at the beginning
    matches = matches.slice(activeIndex);
    if (!findInProject) matches = matches.concat(matches.slice(0, activeIndex));

    return (
      matches.find(match => {
        if (!match.range) return;
        if (match.id !== currentMatch.id || match.field !== currentMatch.field) return true;
        if (gt(match.range[1], currentMatch.range[1])) return true;
        if (gte(match.range[0], currentMatch.range[0]) && !isEqual(match, currentMatch)) return true;
      }) || (findInProject ? null : firstMatch)
    );
  }

  // Get all docs which have matches in order of when you would navigate to them in a project search. Adds the currentDoc
  // even if it doesn't have a match, so we know what is before/after it.
  function getDocSearchList(currentDoc: Doc) {
    const { projectId, parentsLookup, childrenLookup } = projectStore.get();
    const { map } = state;
    const list: Doc[] = [];

    // The current doc may be inside another that would normally be in this list. In that case we want to grab the
    // siblings, not the parent doc.
    const goInto: { [id: string]: true } = {};
    let parent = parentsLookup[currentDoc.id];
    while (parent) {
      goInto[parent.id] = true;
      parent = parentsLookup[parent.id];
    }

    function addToList(doc: Doc) {
      const findSettings = settings.getFor(doc).findSettings;
      if (currentDoc === doc) {
        list.push(doc);
        if (findSettings?.childrenShouldBeSearched && childrenLookup[doc.id]) childrenLookup[doc.id].forEach(addToList);
        if (!findSettings || !findSettings.skip) return;
      }

      if (!goInto[doc.id] && findSettings && findSettings.group) {
        if (hasMatch(doc)) list.push(doc);
      } else if (childrenLookup[doc.id]) {
        if (map[doc.id]) list.push(doc);
        childrenLookup[doc.id].forEach(addToList);
      } else {
        if (hasMatch(doc)) list.push(doc);
      }
    }

    childrenLookup[projectId].forEach(addToList);

    return list;
  }

  function getValidLastSelection() {
    if (
      lastSelection &&
      viewport.getFields().find(({ id, field }) => id === lastSelection.id && field === lastSelection.field)
    ) {
      return lastSelection;
    }
  }

  function hasMatch(doc: Doc) {
    const { map } = state;
    const { childrenLookup } = projectStore.get();
    if (map[doc.id]) return true;
    if (!childrenLookup[doc.id]) return false;
    return childrenLookup[doc.id].some(hasMatch);
  }

  function getDeltaFromIndexes(doc: TextDocument, matches: Ranges) {
    const text = doc && doc.getText();
    if (!text) return;

    const { replaceText } = state;
    const expr = getFindExpression(state);
    const delta = new Delta();
    let lastIndex = 0;

    matches.forEach(([from, to]) => {
      if (from > lastIndex) delta.retain(from - lastIndex);
      const formats = doc.getTextFormat(from);
      if (matchRegex) {
        try {
          const replace = text.slice(from, to).replace(expr, replaceText);
          delta.delete(to - from).insert(replace, formats);
          lastIndex = to;
        } catch (e) {}
      } else {
        delta.delete(to - from).insert(replaceText, formats);
        lastIndex = to;
      }
    });
    return delta;
  }

  return {
    open,
    close,
    openInProject,
    openReplace,
    useCurrentWordForFind,
    findNext,
    findPrevious,
    replace,
    replaceAll,
    update,
    get: findState.get,
    subscribe: findState.subscribe,
  };
}
