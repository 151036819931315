<script>
  import { inform, router, t } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { billing } from '../billing';
  import { applyCode, showCouponModal } from '../referral';
  import Discount from './Discount.svelte';

  let existingCoupon;

  $: subscribed = $billing?.subscription?.status !== 'canceled';
  $: if ($showCouponModal) {
    existingCoupon = $billing.subscription.discount;
  }

  function close() {
    $showCouponModal = false;
  }

  function goToCheckout() {
    router.navigate('/checkout/plans');
    close();
  }

  async function keepCoupon() {
    close();
    return await inform('success', $t('billing_keep_coupon'));
  }

  async function changeCoupon() {
    await applyCode(sessionStorage.referralCode || sessionStorage.coupon);
    close();
    return await inform('success', $t('billing_coupon_accepted'));
  }
</script>

{#if $showCouponModal}
  {#if existingCoupon}
    <Modal title={$t('billing_multi_coupon_title')} on:close={close}>
      <section class="">
        <p>{$t('billing_multi_coupon_text')}</p>
        <p>{$t('billing_multi_coupon_current')}</p>
        <div on:click={keepCoupon} class="select-button">
          <Discount coupon={true} />
        </div>
        <p>{$t('billing_multi_coupon_new')}</p>
        <div on:click={changeCoupon} class="select-button">
          <Discount />
        </div>
      </section>
    </Modal>
  {:else}
    <Modal title={$t('billing_coupon_title')} on:close={close}>
      <section class="">
        {#if subscribed}
          <p>{$t('billing_coupon_subscribed')}</p>
        {:else}
          <p>{$t('billing_coupon_unsubscribed')}</p>
        {/if}
        <p>{$t('billing_coupon_waiting')}</p>
        <Discount />
        <div>
          <button class="btn primary" on:click={goToCheckout}>{$t('billing_subscribe_now')}</button>
          <button class="btn primary" on:click={close}>{$t('billing_subscribe_later')}</button>
        </div>
      </section>
    </Modal>
  {/if}
{/if}

<style>
  .select-button {
    cursor: pointer;
  }
</style>
