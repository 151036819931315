<script lang="ts">
  import {
    confirm,
    currentDoc,
    displayedDocId,
    features,
    findReplace,
    getIcon,
    getTitle,
    getUrl,
    isDocEmpty,
    projectMetas,
    projectStore,
    readonly,
    settings,
    size,
    t,
    trashOrDeleteDoc,
    userDocs,
    viewport,
  } from '@dabble/app';
  import { DocMap } from '@dabble/data/stores/find-replace';
  import { getSetting } from '@dabble/data/stores/settings';
  import { Doc, DocSettings, UserDoc } from '@dabble/data/types';
  import { DropOptions, droppable } from '@dabble/plugins/content/droppable';
  import ContextMenu from '@dabble/toolkit/ContextMenu.svelte';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import { copyDocForDuplication, pasteDocFromDuplication } from '@dabble/toolkit/copy-paste-doc';
  import { addDocToDrag, getDropType, isDropDoc, mimes } from '@dabble/toolkit/drag-drop-utils';
  import { draggable } from '@dabble/toolkit/draggable';
  import { tooltipRight } from '@dabble/toolkit/tooltip';
  import { slide } from '@dabble/toolkit/transitions';
  import { mdiChevronDown, mdiDotsVertical } from '@mdi/js';
  import NavItemEdit from './NavItemEdit.svelte';
  import NavItemMenu from './NavItemMenu.svelte';

  export let doc: Doc;
  export let visibleNav: Set<string> | null = null;
  export let noChildren = false;
  const { focusedDocId } = viewport;
  let editing = false;
  let menuOpen = false;
  let dragCapture = false;
  let dragging = false;
  let dropIndex = -1;

  let opened: boolean;
  let header: Element;
  let container: Element;
  let title: Element;
  let leftDropBoundary: number | null;

  $: meta = doc.id === $projectStore.projectId && doc.docs && $projectMetas[doc.id];
  $: docSettings = settings.getFor(doc) as DocSettings;
  $: userDoc = $userDocs[doc.id] || ({} as Partial<UserDoc>);
  $: docOpened = opened = userDoc.opened === undefined ? getDefaultOpen(docSettings) : userDoc.opened;
  $: docChildren = !noChildren && $projectStore.childrenLookup[doc.id];
  $: children = docChildren && filterChildren(docChildren, visibleNav);
  $: showChildren = children && children.length && !docSettings.hideChildren && docOpened;
  $: found = hasFoundFields($findReplace.map, doc);
  $: foundChild = doc.children && !showChildren && !noChildren && $findReplace.map && foundInChildren(doc);
  $: resetBoundary(doc); // whenever doc changes, reset the leftDropBoundary to be recalculated
  $: hasLabel = !!doc.label;

  function filterChildren(children: Doc[], visibleNav?: Set<string>) {
    const exists = new Set();
    return children.filter(child => {
      if ((visibleNav && !visibleNav.has(child.id)) || exists.has(child.id)) return false;
      exists.add(child.id);
      if (doc.type === 'trash') {
        if (isDocEmpty(child)) return false;
        return true; // Show everything in the trash that has content, even if it is normally hidden in nav
      }
      const docSettings = settings.getFor(child);
      return docSettings && !docSettings.hideInNavigation;
    });
  }

  function getDefaultOpen(docSettings: DocSettings) {
    if (docSettings.requiresFeature && !$features.has(docSettings.requiresFeature)) return false;
    return Boolean(docSettings.openByDefault);
  }

  function onClick(event: MouseEvent) {
    if (typeof docSettings.onSelect === 'function') {
      docSettings.onSelect(doc);
    } else if (docSettings.unselectable || !docSettings.view) {
      if (children && !docSettings.hideFolderToggle) {
        toggleOpen();
      }
    } else {
      return;
    }
    event.preventDefault();
  }

  function editTitle() {
    if (!docSettings.uneditable && !$readonly) {
      editing = true;
    }
  }

  function toggleOpen() {
    userDocs.update(doc.id, { opened: !docOpened });
  }

  function hasFoundFields(findMap: DocMap, doc: Doc, visibleFields?: Record<string, Record<string, boolean>>) {
    if (!findMap || !findMap[doc.id]) return false;
    if (!visibleFields) {
      const { findSettings } = settings.getFor(doc) as DocSettings;
      visibleFields = findSettings && findSettings.visibleFields;
    }
    if (!visibleFields || !visibleFields[doc.type]) return true;
    const fields = visibleFields[doc.type];
    return Object.keys(findMap[doc.id]).some(field => fields[field]);
  }

  function foundInChildren(doc: Doc): boolean {
    const { findSettings } = settings.getFor(doc);
    let visibleFields: Record<string, Record<string, boolean>>;
    if (findSettings && findSettings.group && findSettings.visibleFields) {
      visibleFields = findSettings.visibleFields;
    }
    return (
      projectStore.getChildren(doc.id) &&
      projectStore.getChildren(doc.id).some(child => {
        return hasFoundFields($findReplace.map, child, visibleFields) || foundInChildren(child);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function resetBoundary(doc: Doc) {
    leftDropBoundary = null;
  }

  function getLeftBoundary() {
    if (leftDropBoundary == null) {
      leftDropBoundary = title.getBoundingClientRect().left;
    }
    return leftDropBoundary;
  }

  const dragOptions = {
    start(event: DragEvent) {
      dragCapture = true;
      addDocToDrag(event.dataTransfer, doc);
      copyDocForDuplication(doc);
    },
    captured() {
      dragCapture = false;
      dragging = true;
    },
    end() {
      dragging = false;
    },
  };

  function getDropIndex(event: DragEvent) {
    let target = event.target as Element;
    let index = -1;

    while (target && target.parentNode !== container && target !== header) {
      target = target.parentNode as Element;
    }

    if (target === header || !target || !container) {
      index = 0;
    } else {
      if (target && !target.classList.contains('drop-placeholder')) {
        const rect = target.getBoundingClientRect();
        if (event.pageY > rect.top + rect.height / 2) {
          target = target.nextElementSibling as Element;
        }
      }

      if (target && target.classList.contains('drop-placeholder')) {
        target = target.nextElementSibling as Element;
      }

      index = target ? getChildIndex(target) : children.length;
    }

    return isDropDoc(event.dataTransfer, children[index]) || isDropDoc(event.dataTransfer, children[index - 1])
      ? -1
      : index;
  }

  function getChildIndex(target: Element) {
    return Array.from(container.children)
      .filter(elem => !elem.classList.contains('drop-placeholder'))
      .indexOf(target);
  }

  function forParent(event: DragEvent) {
    return event.clientX < getLeftBoundary();
  }

  const dropOptions: DropOptions = {
    mimes,
    dropEffect: 'move',
    acceptable(event) {
      if (header.closest('.dragging') || forParent(event)) {
        dropIndex = -1;
        return false;
      }
      const type = getDropType(event.dataTransfer);
      const index = forParent(event) ? -1 : getDropIndex(event);
      const doc: Doc = { id: '', type };
      return (type && !docSettings.validChildTypes) || getSetting(docSettings.validChildTypes[type], doc, index);
    },
    over(event) {
      const index = forParent(event) ? -1 : getDropIndex(event);
      if (index !== dropIndex) dropIndex = index;
    },
    leave() {
      dropIndex = -1;
    },
    drop(event, mime, data) {
      dropIndex = -1;
      let index = forParent(event) ? -1 : getDropIndex(event);
      if (index === -1) return;

      // Adjust index if it comes after the dragged item
      const dragged = container && container.querySelector(`[data-id="${doc.id}"] > .nav-container > .dragging`);
      if (dragged && getChildIndex(dragged) < index) index--;

      // Adjust for virtual items
      for (let i = 0; i < index; i++) {
        if (docChildren[i].virtual) index--;
      }

      if (projectStore.getDoc(data.id)) {
        projectStore.moveDoc(data.id, doc.id, index);
      } else {
        delete data.id;
        pasteDocFromDuplication(doc, getDropIndex(event));
      }
    },
  };

  async function onKeyUp(event: KeyboardEvent) {
    event.stopPropagation();
    const keyCode = event.keyCode;
    // 46 = Delete Key
    if (keyCode === 46 && doc.id === $displayedDocId) {
      event.preventDefault();
      if (!docSettings?.noDeleteInNavigation && !editing) {
        if (await confirm($t('delete'), $t('delete_confirm', { title: getTitle(doc) }))) {
          trashOrDeleteDoc(doc.id);
        }
      }
    }
  }
</script>

<div
  data-id={doc.id}
  class="nav-item {doc.type}"
  class:focused-doc={doc.id === $focusedDocId || (!docOpened && projectStore.contains(doc, $focusedDocId))}
  class:selected={doc === $currentDoc}
  class:selectable={!docSettings.unselectable && docSettings.view}
  class:opened
  class:found
  class:child-selected={!docOpened && $currentDoc && projectStore.contains(doc, $currentDoc)}
  class:child-found={foundChild}
  class:children-showing={showChildren}
  transition:slide|local={{ duration: 150 }}
  class:dragging
  class:virtual={doc.virtual}
  use:droppable={children && dropOptions}
  on:keyup={onKeyUp}
>
  <header
    class="nav-item-header"
    bind:this={header}
    class:menu-open={menuOpen}
    class:editing
    class:drag-capture={dragCapture}
  >
    {#if children && !docSettings.hideChildren && !docSettings.hideFolderToggle}
      <button on:click={toggleOpen} class:hover-only={docSettings.hoverFolderToggle} class="toggle-arrow">
        <Icon path={mdiChevronDown} />
      </button>
    {/if}

    <a
      bind:this={title}
      class="title"
      href={getUrl(doc)}
      on:click={onClick}
      on:dblclick={editTitle}
      use:draggable={!editing && !docSettings.undraggable && !doc.virtual && dragOptions}
    >
      {#if docSettings.icon}
        <Icon path={getIcon(doc)} overlay={doc.iconOverlay} />
      {/if}
      {#if !editing}
        <Title class={'text'} doc={meta || doc} />
        {#if docSettings.navTitlePostfix}
          <span class="nav-title-postfix">
            {docSettings.navTitlePostfix(doc)}
          </span>
        {/if}
      {:else}
        <NavItemEdit {doc} on:done={() => (editing = false)} />
      {/if}
    </a>

    <div class="actions">
      <button
        class="icon menu-opener"
        on:click={() => (menuOpen = true)}
        use:tooltipRight={$size === 'desktop' && $t('nav_item_actions', { type: doc.type })}
      >
        <Icon path={mdiDotsVertical} />
      </button>
      {#if menuOpen}
        <Dropdown
          placement={$size !== 'desktop' ? 'left-start' : 'right-start'}
          arrow
          on:close={() => (menuOpen = false)}
        >
          <NavItemMenu {doc} on:editTitle={editTitle} />
        </Dropdown>
      {/if}
    </div>
  </header>
  {#if showChildren && children}
    <div class="nav-container" bind:this={container} transition:slide|local={{ duration: 150 }}>
      {#each children as doc, index (doc.id)}
        {#if dropIndex === index}
          <div class="drop-placeholder" />
        {/if}
        <svelte:self {doc} {visibleNav} />
      {/each}
      {#if dropIndex === children.length}
        <div class="drop-placeholder" />
      {/if}
    </div>
  {:else if children}
    <div class="nav-container" bind:this={container}>
      {#if dropIndex === 0}
        <div class="drop-placeholder" />
      {/if}
    </div>
  {/if}
  {#if hasLabel}
    <div class="nav-label-marker" style="--ribbon-color: var(--ribbon-color-{doc.label.color}, #ccc);" />
  {/if}
</div>

<ContextMenu target={header} activeClass="menu-open">
  <NavItemMenu {doc} on:editTitle={editTitle} />
</ContextMenu>

<style>
  .nav-title-postfix {
    padding-left: 8px;
  }
  .nav-item {
    display: block;
    contain: layout;
  }

  .nav-item .nav-item-header {
    display: flex;
    align-items: center;
    color: var(--text-color-light);
    font-weight: 400;
    font-size: var(--font-size-sm);
    line-height: var(--side-nav-item-height);
    padding-left: var(--side-nav-item-padding);
    border-radius: 0 40px 40px 0;
    position: relative;
    height: var(--side-nav-item-height);
    contain: layout size;
    margin-right: 4px;
  }
  .nav-item .nav-item-header:not(.editing) {
    contain: strict;
  }

  .nav-item > .nav-item-header > .title {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    padding-right: 32px;
    text-decoration: none;
    color: inherit;
  }
  .nav-item > .nav-item-header > .title :global(.edit-input) {
    margin-right: -34px;
  }
  :global(.is-mouse) .nav-item > .nav-item-header > .title {
    padding-right: 4px;
  }
  .nav-item > .nav-item-header > .title :global(.edit-input) {
    margin-right: -6px;
  }
  :global(.is-mouse) .nav-item > .nav-item-header:not(.editing):hover > .title,
  :global(.is-mouse) .nav-item > .nav-item-header:not(.editing).menu-open > .title {
    padding-right: 18px;
  }
  .nav-item > .nav-item-header.drag-capture > .title {
    background-color: var(--side-nav-background);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    opacity: 0.99; /* force transparency in the corners */
  }
  .nav-item.selectable > .nav-item-header > .title {
    cursor: pointer;
  }
  .nav-item .nav-item-header :global(.text) {
    flex: 0 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .nav-item .title :global(.icon) {
    margin-right: 4px;
    font-size: 20px;
    flex-shrink: 0;
  }
  .nav-item > .nav-item-header.drag-capture > :not(.title) {
    visibility: hidden;
  }

  .nav-item.focused-doc:not(.selected):not(.child-selected) > .nav-item-header:not(.drag-capture)::before {
    position: absolute;
    content: '';
    left: -10px;
    top: calc((var(--side-nav-item-height) - 10px) / 2 - 5px);
    height: 18px;
    width: 18px;
    border-radius: 0 2px;
    transform: scaleX(0.6) rotate(45deg);
    background: var(--dabble-blue);
  }
  .nav-item.focused-doc:not(.selected):not(.child-selected) > .nav-item-header:not(.drag-capture) {
    background-color: var(--side-nav-color-light);
    color: var(--text-color-normal);
  }
  .nav-item.focused-doc.selected > .nav-item-header:not(.drag-capture)::before,
  .nav-item.focused-doc.selected > .nav-item-header:not(.drag-capture)::after {
    background: var(--white);
    box-shadow: var(--side-nav-selected-text-shadow);
  }
  :global(.is-mouse body:not(.drag-drop))
    .nav-item.selectable:not(.selected)
    > .nav-item-header:not(.drag-capture):hover,
  :global(body:not(.drag-drop)) .nav-item.selectable:not(.selected) > .nav-item-header:not(.drag-capture).menu-open {
    background-color: var(--side-nav-color-light);
    color: var(--text-color-normal);
  }
  .nav-item.selected > .nav-item-header:not(.drag-capture) {
    background: var(--dabble-blue);
    position: relative;
    z-index: 1;
    color: var(--side-nav-selected-text-color);
    text-shadow: var(--side-nav-selected-text-shadow);
    transition: box-shadow 150ms ease-out;
  }
  .nav-item.child-selected > .nav-item-header:not(.drag-capture) {
    box-shadow: 0 3px 0 rgba(var(--dabble-blue-rgb), 30%), 0 2px 0 rgba(var(--dabble-blue-rgb), 50%),
      0 1px 0 rgba(var(--dabble-blue-rgb), 70%);
    position: relative;
    z-index: 1;
    transition: box-shadow 150ms 50ms ease-in;
  }

  /* Toggle Arrow */
  .nav-item .toggle-arrow {
    position: relative;
    margin-left: calc(-1 * var(--side-nav-item-padding));
    width: var(--side-nav-item-padding);
    height: var(--side-nav-item-height);
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    padding: none;
  }
  :global(.is-mouse) .nav-item .toggle-arrow.hover-only {
    display: none;
  }
  .nav-item > .nav-item-header:hover .toggle-arrow.hover-only {
    display: flex;
  }
  .nav-item > .nav-item-header.editing .toggle-arrow {
    display: none;
  }
  .nav-item .toggle-arrow :global(.icon) {
    font-size: 20px;
    color: var(--side-nav-icon-color);
    transition: transform 0.15s;
  }
  .nav-item.selected > .nav-item-header .toggle-arrow :global(.icon) {
    color: var(--side-nav-icon-selected-color);
  }
  .nav-item .toggle-arrow:hover :global(.icon) {
    color: var(--side-nav-icon-hover-color);
  }
  .nav-item.selected > .nav-item-header .toggle-arrow:hover :global(.icon) {
    color: var(--side-nav-icon-selected-hover-color);
  }
  .nav-item:not(.opened) > .nav-item-header .toggle-arrow :global(.icon) {
    transform: rotate(-90deg);
  }

  .nav-item .nav-container {
    transition: all 150ms ease-in-out;
  }

  /* Left Padding */
  .nav-item .nav-container {
    display: block;
    padding-left: var(--side-nav-item-inset);
    contain: layout;
  }
  :global(.nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -1);
    padding-left: calc(var(--side-nav-item-inset) * 2);
  }
  :global(.nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -2);
    padding-left: calc(var(--side-nav-item-inset) * 3);
  }
  :global(.nav-item .nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -3);
    padding-left: calc(var(--side-nav-item-inset) * 4);
  }
  :global(.nav-item .nav-item .nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -4);
    padding-left: calc(var(--side-nav-item-inset) * 5);
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -5);
    padding-left: calc(var(--side-nav-item-inset) * 6);
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -6);
    padding-left: calc(var(--side-nav-item-inset) * 7);
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -7);
    padding-left: calc(var(--side-nav-item-inset) * 8);
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item .nav-container {
    margin-left: calc(var(--side-nav-item-inset) * -8);
    padding-left: calc(var(--side-nav-item-inset) * 9);
  }

  .nav-item .nav-item-header {
    padding-left: calc(var(--side-nav-item-padding));
  }
  :global(.nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -1);
    padding-left: calc(var(--side-nav-item-inset) + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -2);
    padding-left: calc(var(--side-nav-item-inset) * 2 + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -3);
    padding-left: calc(var(--side-nav-item-inset) * 3 + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item .nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -4);
    padding-left: calc(var(--side-nav-item-inset) * 4 + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -5);
    padding-left: calc(var(--side-nav-item-inset) * 5 + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -6);
    padding-left: calc(var(--side-nav-item-inset) * 6 + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -7);
    padding-left: calc(var(--side-nav-item-inset) * 7 + var(--side-nav-item-padding));
  }
  :global(.nav-item .nav-item .nav-item .nav-item .nav-item .nav-item .nav-item .nav-item) .nav-item header {
    margin-left: calc(var(--side-nav-item-inset) * -8);
    padding-left: calc(var(--side-nav-item-inset) * 8 + var(--side-nav-item-padding));
  }

  /* Nav item actions */

  .nav-item-header > .actions {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    display: block;
  }
  :global(.is-mouse) .nav-item-header > .actions {
    display: none;
  }
  .nav-item:not(.dragging) > .nav-item-header:hover > .actions,
  .nav-item-header.menu-open > .actions {
    display: block;
  }

  .nav-item .menu-opener {
    font-size: 32px;
    text-shadow: none;
  }
  :global(.is-mouse) .nav-item .menu-opener,
  :global(.prefer-desktop) .nav-item .menu-opener {
    font-size: 20px;
    padding: 2px;
  }
  .nav-item .menu-opener:disabled {
    display: none;
  }
  .nav-item .menu-opener:hover:not(:disabled) {
    color: var(--side-nav-icon-hover-color);
  }
  .nav-item.selected > header .menu-opener {
    color: var(--side-nav-icon-selected-color);
  }
  .nav-item.selected > header .menu-opener:hover:not(:disabled) {
    color: var(--side-nav-icon-selected-color);
  }
  .dropdown-item.add-child {
    position: relative;
  }
  .drop-placeholder {
    box-sizing: border-box;
    border: 1px dashed #66afe9;
    border-radius: 3px;
    background: #e5ecf2;
    margin-left: var(--side-nav-item-padding);
    min-height: var(--side-nav-item-height);
  }
  .nav-item.dragging {
    opacity: 0.3;
    height: var(--side-nav-item-height);
    overflow: hidden;
  }

  .found > .nav-item-header:not(.drag-capture) > .title,
  .child-found > .nav-item-header:not(.drag-capture) > .title {
    background: yellow;
    color: var(--text-color-light);
    text-shadow: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  .nav-item.dropping .nav-container {
    position: relative;
  }
  .nav-item.dropping > .nav-container::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: -4px;
    margin-left: -4px;
    box-shadow: inset 1px 0 0 rgba(102, 175, 233, 0.3), inset 2px 0 0 rgba(102, 175, 233, 0.3);
    border-radius: 3px;
  }

  .nav-label-marker {
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: var(--side-nav-item-height);
    background-color: var(--ribbon-color);
  }
</style>
