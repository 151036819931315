<script>
  import { currentProjectMeta, desktop, documents, isApple, router, size } from '@dabble/app';
  import Blank from '@dabble/blank.html';
  import { onDestroy, onMount, tick } from 'svelte';
  import ContextMenu from './ContextMenu.svelte';
  import InputContextMenu from './InputContextMenu.svelte';
  import { listen, unlisten } from './interactions';

  const isIOSChrome = navigator.userAgent.includes('CriOS');
  const isChrome = !isIOSChrome && !!window.chrome;
  const usePage = isChrome;
  const src = usePage ? Blank : undefined;

  export let component;
  export let autofocus = false;
  let className = '';
  export { className as class };

  let frame;
  let win;
  let doc;
  let head;
  let body;
  let content;
  let routerListener;
  let font;

  $: font = $currentProjectMeta?.font || {};
  $: body &&
    (body.className =
      `${isApple ? 'isApple ' : ''}${desktop.inApp ? 'inDesktop ' : ''}` +
      ` size-${$size}` +
      ` font-${font.family || 'general'}`);
  $: body &&
    (font.size ? body.setAttribute('style', `--font-size: ${font.size / 12}rem`) : body.removeAttribute('style'));
  $: {
    if (content) {
      const { component, autofocus, ...props } = $$props;
      delete props.class;
      content.$set(props);
    }
  }

  function loadHandler() {
    win = frame.contentWindow;
    doc = frame.contentDocument;
    head = doc.head;
    body = doc.body;

    if (!usePage) {
      // set default body styles
      const styleElement = doc.createElement('style');
      styleElement.innerHTML = `html,body {
      background: none !important;
      margin: 0 !important;
      padding:0 !important;
      overflow: hidden !important;
      height: 100vh !important;
    }
    body { display: flex; flex-direction: column; }`;
      head.appendChild(styleElement);
    }

    Array.from(document.querySelectorAll('style, link[rel="stylesheet"]')).forEach(node =>
      head.appendChild(node.cloneNode(true))
    );

    listen(doc);
    routerListener = router.listen(win);

    if (component) {
      const { component, autofocus, className, ...props } = $$props;
      delete props.class;
      content = new component({
        target: body,
        immutable: true,
        props,
      });
    }

    if (autofocus) {
      win.focus();
    }

    documents.add(doc);
  }

  function inputElement(event) {
    if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA') {
      return event.target;
    }
  }

  onMount(async () => {
    await tick();
    if (!frame) return; // unmounted already
    if (
      !usePage &&
      frame.contentDocument &&
      frame.contentDocument.readyState === 'complete' &&
      frame.contentDocument.defaultView
    ) {
      loadHandler();
    } else {
      frame.addEventListener('load', loadHandler);
    }
  });

  onDestroy(() => {
    if (frame) frame.removeEventListener('load', loadHandler);
    if (content) content.$destroy();
    if (routerListener) routerListener();
    if (doc) {
      unlisten(doc);
      documents.remove(doc);
    }
  });
</script>

<iframe class={className} bind:this={frame} {src} title="frame" />

<ContextMenu target={doc} targetFilter={inputElement} let:target>
  <InputContextMenu {target} />
</ContextMenu>

<style>
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
</style>
