import { Readable, Writable, writable } from './store';

// export type Feature = 'unlimited_projects' | 'story_notes' | 'plotting' | 'goals' | 'dark_mode'
//   | 'spell_check' | 'grammar'
//   | 'support_low' | 'support_email' | 'support_chat';
export type Features = Set<string>;

export interface FeaturesStore extends Readable<Features> {
  upgradeFeature: Writable<string>;
  add: (feature: string | string[]) => void;
  remove: (feature: string | string[]) => void;
  replace: (features: string[]) => void;
  clear(): void;
  promptUpgrade(feature: string): void;
}

const liveFeatures = new Set([
  'unlimited_projects',
  'goals',
  'focus',
  'story_notes',
  'plotting',
  'dark_mode',
  'spell_check',
  'grammar',
  'coauthoring',
  'stickies',
  'comments',
  // 'versioning',
  // 'images',
  'support_low',
  'support_email',
  'support_chat',
  'thesaurus',
  'text_to_speech',
]);

export function createFeaturesStore(): FeaturesStore {
  const { get, set, update, subscribe } = writable(new Set<string>());
  const upgradeFeature = writable('');

  function add(feature: string | string[]) {
    const values = Array.isArray(feature) ? feature : [feature];
    if (values[0] === 'all') return set(liveFeatures);
    update(features => {
      features = new Set(features);
      values.forEach(value => liveFeatures.has(value) && features.add(value));
      return features;
    });
  }

  function remove(feature: string | string[]) {
    update(features => {
      features = new Set(features);
      if (Array.isArray(feature)) {
        feature.forEach(features.delete.bind(features));
      } else {
        features.delete(feature);
      }
      return features;
    });
  }

  function replace(features: string[]) {
    if (features[0] === 'all') set(liveFeatures);
    else update(() => new Set(features.filter(f => liveFeatures.has(f))));
  }

  function clear() {
    update(() => new Set());
  }

  function promptUpgrade(feature: string) {
    upgradeFeature.set(feature);
  }

  return {
    upgradeFeature,
    add,
    remove,
    replace,
    clear,
    promptUpgrade,
    get,
    subscribe,
  };
}
