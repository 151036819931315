export interface InvalidElements {
  [name: string]: string;
}

export function updateValidity(invalid: InvalidElements, element: HTMLInputElement) {
  if (invalid) {
    const name = element.id || element.name;
    const { [name]: removed, ...updated } = invalid;
    return updated;
  }
  return invalid;
}

export function checkValidity(form: HTMLFormElement) {
  if (form.checkValidity()) return;
  const invalid: InvalidElements = {};
  const elements = form.elements;
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLInputElement;
    if (element.validationMessage) {
      invalid[element.id || element.name] = element.validationMessage;
    }
  }
  return invalid;
}
