import {
  alert,
  authed,
  confirm, delegate,
  getDateString,
  globalData,
  loadingQuoteCleared,
  plugins,
  projectGoalStore,
  projectStore,
  readonly,
  router,
  shownModal,
  t,
  today,
  userMeta
} from '@dabble/app';
import { observe } from '@dabble/data/observe';
import rest from '@dabble/data/rest';
import { createGlobalDataTypeStore } from '@dabble/data/stores/global-data';
import { Readable, derived } from '@dabble/data/stores/store';
import { UserMetaStore, UserMetaTypeStore, createUserMetaVariableStore } from '@dabble/data/stores/user-meta';
import { GlobalData, UserMeta } from '@dabble/data/types';
import { number } from '@dabble/toolkit/helpers';
import interactions, { hasFocus } from '@dabble/toolkit/interactions';
import log from '@dabble/util/log';
import { addMonths, differenceInMonths, setDate, startOfMonth } from 'date-fns';
import { target, totalCount } from './goals';

export interface NaNoNovel {
  id: number;
  active: boolean;
  title: string;
  wordcount: number;
}

export interface NaNoWriMo extends UserMeta {
  auth?: string; // auth token
  participating?: boolean;
  paused?: boolean;
  projectId?: string;
  username?: string;
  novel?: NaNoNovel;
  excludeCountOffered?: boolean;
}

export interface NaNoWriMoData extends GlobalData {
  enabled: boolean;
  months: number[];
  inviteOn: number[];
}

interface NaNoWriMoStore extends UserMetaTypeStore<NaNoWriMo> {
  submitWordcount: (force?: boolean) => void;
}

function createNaNoWriMoStore(userMeta: UserMetaStore, nanowrimoKey: Readable<string>): NaNoWriMoStore {
  const { get, subscribe, update } = createUserMetaVariableStore<NaNoWriMo>(userMeta, nanowrimoKey);
  return { submitWordcount, get, subscribe, update };
}

const eventMonths = [3, 6, 10];

// Helpers to work with the next NaNoWriMo event's data
const month = derived(today, today => startOfMonth(today));

// Helpers to work with the next NaNoWriMo event's data
export const monthOf = derived(month, month => {
  const monthNum = month.getMonth();
  return new Date(
    month.getFullYear(),
    eventMonths.find(m => m === monthNum || m === monthNum - 1 || m === monthNum + 1 || m > monthNum)
  );
});

export const monthPrior = derived(monthOf, monthOf => addMonths(monthOf, -1));
export const isCamp = derived(monthOf, monthOf => monthOf.getMonth() !== 10);
export const monthTwentieth = derived(monthOf, monthOf => setDate(monthOf, 20));
export const monthAfter = derived(monthOf, monthOf => addMonths(monthOf, 1));
export const monthAfterSecond = derived(monthAfter, monthAfter => setDate(monthAfter, 2));
export const nanowrimoKey = derived(
  monthOf,
  monthOf => `nanowrimo-${getDateString(monthOf).split('-').slice(0, 2).join('-')}`
); // e.g. 2020-04

export const nanowrimoData = createGlobalDataTypeStore<NaNoWriMoData>(globalData, 'nanowrimoData');
export const nanowrimoEnabled = derived(
  [nanowrimoData, monthOf, month],
  ([nanowrimoData, monthOf, month]) => nanowrimoData.enabled && Math.abs(differenceInMonths(monthOf, month)) < 2
);
export const nanowrimo = createNaNoWriMoStore(userMeta, nanowrimoKey);
export const nanowrimoAuthExpires = derived(nanowrimo, nanowrimo => getAuthExpiry(nanowrimo.auth));
export const nanowrimoAuthExpired = derived(
  [nanowrimoAuthExpires, today],
  ([expires, today]) => (expires && expires < today) || false
);
export const nanowrimoTime = derived(
  [nanowrimoEnabled, monthOf, monthAfter, today],
  ([nanowrimoEnabled, monthOf, monthAfter, today]) => nanowrimoEnabled && today >= monthOf && today < monthAfter
);
export const nanowrimoPrepTime = derived(
  [nanowrimoEnabled, monthOf, monthPrior, today],
  ([nanowrimoEnabled, monthOf, monthPrior, today]) => nanowrimoEnabled && today >= monthPrior && today < monthOf
);
export const nanowrimoTrialTime = derived(
  [nanowrimoEnabled, monthPrior, monthAfterSecond, today],
  ([nanowrimoEnabled, monthPrior, monthAfterSecond, today]) =>
    nanowrimoEnabled && today >= monthPrior && today < monthAfterSecond
);
export const nanowrimoSubmitTime = derived(
  [nanowrimoEnabled, monthTwentieth, monthAfterSecond, today],
  ([nanowrimoEnabled, monthTwentieth, monthAfterSecond, today]) =>
    nanowrimoEnabled && today >= monthTwentieth && today < monthAfterSecond
);
export const nanowrimoIsNow = derived(
  [nanowrimoEnabled, monthPrior, monthAfter, today],
  ([nanowrimoEnabled, monthPrior, monthAfter, today]) => nanowrimoEnabled && today >= monthPrior && today < monthAfter
);
export const nanowrimoParticipating = derived(
  [nanowrimoTrialTime, nanowrimo],
  ([itsTime, nanowrimo]) => itsTime && nanowrimo && nanowrimo.participating
);
export const forNaNoWriMo = derived(
  [nanowrimoParticipating, nanowrimo, projectStore],
  ([participating, nanowrimo, project]) => participating && nanowrimo.projectId === project.projectId
);

plugins.register({
  nanowrimo,
  nanowrimoTime,
  nanowrimoPrepTime,
  nanowrimoTrialTime,
  nanowrimoSubmitTime,
  nanowrimoIsNow,
  nanowrimoParticipating,
  nanowrimoAuthExpires,
});

const isNaNoMessageReady = derived([plugins, nanowrimoIsNow, loadingQuoteCleared], ([plugins, isNow, quoteCleared]) => {
  return plugins.currentUser && isNow && quoteCleared;
});

let timeout1: any, timeout2: any;

observe([isNaNoMessageReady, isCamp, nanowrimoParticipating], ([isNaNoMessageReady, isCamp, participating]) => {
  clearTimeout(timeout1);
  if (isNaNoMessageReady && !isCamp && participating === undefined) {
    timeout1 = setTimeout(() => shownModal.set('nanowrimoInvite'), 2000);
  }
});

observe(
  [isNaNoMessageReady, nanowrimoParticipating, nanowrimoAuthExpired],
  ([isNaNoMessageReady, participating, expired]) => {
    clearTimeout(timeout2);
    if (isNaNoMessageReady && participating && expired) {
      timeout2 = setTimeout(async () => {
        const confirmed = await confirm(
          t.get()('confirm_header_nanowrimo_reauth'),
          t.get()('confirm_nanowrimo_reauth'),
          { yesNo: true }
        );
        if (confirmed) {
          router.navigate('/nanowrimo/auth');
        } else if (confirmed === false) {
          nanowrimo.update({ auth: null });
        }
      }, 2000);
    }
  }
);

observe(
  [target, forNaNoWriMo, nanowrimoTime, nanowrimo, monthOf],
  ([target, forNaNoWriMo, nanowrimoTime, nanowrimo, monthOf]) => {
    if (
      target &&
      forNaNoWriMo &&
      nanowrimoTime &&
      nanowrimo.modified < monthOf.getTime() &&
      !nanowrimo.excludeCountOffered
    ) {
      offerToSetExistingWordcount();
    }
  }
);

setTimeout(() => {
  if (delegate) return;
  observe([target, totalCount, projectStore, forNaNoWriMo], ([target, totalCount, project, forNaNoWriMo]) => {
    if (target && !project.currentVersion) {
      queueSubmitWordcount(totalCount, forNaNoWriMo);
    }
  });
}, 10000);

async function offerToSetExistingWordcount() {
  const total = totalCount.get();
  if (total > 0 && !projectGoalStore.get().start) {
    const message = t.get()('nanowrimo_existing_word_count_message', { word_count: number(total) });

    if (await confirm(t.get()('nanowrimo_existing_word_count'), message, { yesNo: true })) {
      await projectGoalStore.update({ start: total });
      alert(t.get()('alert_header_updated'), t.get()('alert_updated'));
    } else {
      alert(t.get()('alert_header_sounds_good'), t.get()('alert_header_sounds_good'));
    }
  }

  nanowrimo.update({ excludeCountOffered: true });
}

let submitTimeout: any;
let lastSubmitted: number;
let lastTotal: number;

async function queueSubmitWordcount(totalCount: number, forNaNoWriMo: boolean) {
  const $nanowrimo = nanowrimo.get();
  if (!forNaNoWriMo || $nanowrimo.paused || readonly.get()) return;

  if (!nanowrimoAuthExpired.get() && nanowrimoTime.get() && lastTotal !== undefined && totalCount !== lastTotal) {
    if (!submitTimeout) submitTimeout = setTimeout(submitWordcount, 120000);
  }

  if (lastSubmitted === undefined) lastSubmitted = lastTotal;
  lastTotal = totalCount;
}

function getAuthExpiry(auth: string) {
  if (auth) {
    try {
      const payload = JSON.parse(atob(auth.split('.')[1]));
      return new Date(payload.exp * 1000);
    } catch (err) { }
  }
}

async function submitWordcount(force?: boolean) {
  submitTimeout = null;
  if (!target.get() || projectStore.get().currentVersion || !forNaNoWriMo.get()) {
    if (force) throw new Error('This project is not set up for NaNoWriMo');
    return;
  }
  if (force !== true && lastSubmitted === lastTotal) return;
  lastSubmitted = lastTotal = totalCount.get();
  log.tagColor('NaNoWriMo', '#4F321C', 'Updating word count:', lastTotal);
  dispatchEvent(new CustomEvent('nanowordcount', { detail: { wordcount: lastTotal } }));
  if (authed.get()) {
    await rest.PUT('nanowrimo/wordcount', { lastTotal }, { 'x-nanowrimo-auth': nanowrimo.get().auth });
  }
}

interactions.on('blur', () => {
  if (hasFocus()) return;
  if (submitTimeout) submitWordcount();
});

addEventListener('beforeunload', () => {
  if (submitTimeout) submitWordcount();
});
