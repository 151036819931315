import { delegate, deviceId } from '@dabble/app';
import { ConnectionStore, createConnectionStore } from '@dabble/data/connection';
import { writable } from '@dabble/data/stores/store';
import { version } from '@dabble/version';

const INACTIVE_TIMEOUT = 1000 * 60 * 10; // 10 minutes

export let connection: ConnectionStore;
let isDisconnected = false;
let activityTimeout: any;

export const connectionStore = writable(connection);
(window as any).connectionStore = connectionStore;

export function createConnection() {
  connection = createConnectionStore(version, deviceId, delegate);
  connection.connect();
  connectionStore.set(connection);
}

/**
 * Once there has been no activity for a certain amount of time, disconnect from the server. Reconnect as soon as
 * there is more activity again.
 */
export function hadActivity() {
  if (isDisconnected) {
    connect();
  }
  clearTimeout(activityTimeout);
  activityTimeout = setTimeout(() => {
    console.log('No activity for a while, disconnecting');
    isDisconnected = true;
    connection.disconnect();
  }, INACTIVE_TIMEOUT);
}

function connect() {
  isDisconnected = false;
  connection.connect().catch(err => {}); // will automatically retry if it fails
  hadActivity();
}
