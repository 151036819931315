import { observe } from '@dabble/app';
import { Connection, ConnectionStore } from '@dabble/data/connection';
import { Readable, writable } from '@dabble/data/stores/store';
import log from '@dabble/util/log';
import { Tab, To } from 'tab-election';
import { connection, hadActivity } from '../active-connection';
import * as migrations from '../steps/available-migrations';
import { SyncState } from './syncing';

const callQueue: any[][] = [];
const defaultData: State = {
  online: false,
  connected: false,
  authed: false,
  serverTimeOffset: 0,
  syncing: null,
};

export interface State extends Connection {
  syncing: SyncState;
}

export interface TabStore extends Readable<State> {
  waitForLeadership(fn: () => void): void;
  set(updates: Partial<State>): void;
  call<T>(name: string, ...rest: any[]): Promise<T>;
  broadcast(name: string, ...rest: any[]): void;
  close(): void;
}

export function createTabStore(name: Readable<string>): TabStore {
  let tab: Tab<State>;
  let fn = writable<() => any>(null);
  const { get, set, subscribe } = writable(defaultData);

  function close() {
    if (tab) {
      tab.removeEventListener('message', onMessage);
      tab.close();
      tab = null;
      set(defaultData);
    }
  }

  function call<T>(name: string, ...rest: any[]): Promise<T> {
    if (tab) return tab.call(name, ...rest);
    return new Promise<T>((resolve, reject) => {
      callQueue.push([name, rest, resolve, reject]);
    });
  }

  function broadcast(name: string, ...rest: any[]) {
    if (tab) tab.send({ name, rest }, To.All);
  }

  function onMessage(e: MessageEvent) {
    if (e.data?.name === 'migration') {
      const [migrationName, ...args] = e.data.rest as any[];
      migrations[migrationName as any as keyof typeof migrations].apply(null, args);
    }
  }

  // Connect to the tab-election channel after name and fn are both set
  observe([name, fn], ([name, fn]) => {
    if (tab) close();
    if (!name || !fn) return;
    tab = new Tab(name);
    tab.addEventListener('message', onMessage);
    tab.waitForLeadership(() => {
      log.tagColor('Tab', '#ea580c', 'Became tab-election leader for channel', name);
      fn();
      // fn() creates the connection
      const { connect, disconnect, record, recordUserData } = connection;
      function connectionSend(name: keyof ConnectionStore, ...rest: any[]) {
        if (name in connection && typeof connection[name] === 'function') {
          return (connection[name] as Function)(...rest);
        } else {
          return connection.send(name, ...rest);
        }
      }
      const connectionSendAfterAuthed = connection.sendAfterAuthed;
      return { hadActivity, connect, disconnect, record, recordUserData, connectionSend, connectionSendAfterAuthed };
    });
    tab.addEventListener('state', (e: MessageEvent) => set(e.data));
    log.tagColor('Tab', '#ea580c', 'Connected to tab-election channel', name);

    // Call queued calls now
    callQueue.forEach(([name, rest, resolve, reject]) => {
      tab.call(name, ...rest).then(resolve, reject);
    });
    callQueue.length = 0;
  });

  return {
    waitForLeadership: fn.set,
    get,
    set: (updates: Partial<State>) => tab?.setState({ ...tab?.getState(), ...updates }),
    close,
    call,
    broadcast,
    subscribe,
  };
}

if (typeof BroadcastChannel !== undefined) {
  // Clean up old localStorage keys for the old tab communcation mechanism
  for (let i = 0, l = localStorage.length; i < l; i++) {
    const key = localStorage.key(i);
    if (key?.includes('election-')) {
      localStorage.removeItem(key);
    }
  }
}
