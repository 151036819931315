<script>
  import {
    alert,
    confirm,
    features,
    getTitle,
    readonly as globalReadonly,
    isDocEmpty,
    projectStore,
    settings,
    t,
  } from '@dabble/app';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiDelete, mdiPlusThick } from '@mdi/js';
  import PlotGridColumn from './PlotGridColumn.svelte';
  import PlotRowActions from './PlotRowActions.svelte';

  export let doc;

  const emptyLine = { type: 'novel_plot_line' };

  let highlight;
  let emptyColumn;
  let link;

  $: emptyColumn = doc.grid && doc.grid.length ? doc.grid[0].slice(1).fill(null) : [null];
  $: book = (link = projectStore.linksFrom(doc.id, 'plot')[0]) && link.to;
  $: children = $projectStore.childrenLookup[doc.id] || [];
  $: readonly = $globalReadonly || !$features.has('plotting');

  async function deleteColumn(x) {
    const lineId = doc.grid[x][0];
    if (isDocEmpty(lineId) || (await confirm($t('send_to_trash'), $t('novel_delete_column_confirm')))) {
      projectStore.trashDoc(lineId);
    }
  }

  function insertRow(y) {
    const projectPatch = projectStore.patch();
    doc.grid.forEach((col, x) => {
      projectPatch.patch.add(`/docs/${doc.id}/grid/${x}/${y}`, null);
    });
    projectPatch.save();
  }

  async function deleteRow(y) {
    const hasScene = book && doc.grid[0][y];
    const isRowEmpty = !hasScene && doc.grid.every(col => !col[y] || isDocEmpty(col[y]));
    const columns = hasScene ? doc.grid.slice(1) : doc.grid;
    const docIds = columns.map(col => col[y]).filter(Boolean);

    if (hasScene && !docIds.length) {
      return await alert($t('novel_delete_row_with_scene_only_title'), $t('novel_delete_row_with_scene_only'));
    }

    const confirmMessage = hasScene ? 'novel_delete_row_with_scene_confirm' : 'novel_delete_row_confirm';

    if (isRowEmpty || (await confirm($t(confirmMessage)))) {
      const projectPatch = projectStore.patch();
      docIds.forEach(docId => projectPatch.trashDoc(docId));

      if (hasScene) {
        doc.grid.forEach((col, x) => {
          if (x && doc.grid[x][y]) {
            projectPatch.patch.replace(`/docs/${doc.id}/grid/${x}/${y}`, null);
          }
        });
      } else {
        doc.grid.forEach((col, x) => {
          projectPatch.patch.remove(`/docs/${doc.id}/grid/${x}/${y}`);
        });
      }

      projectPatch.save();
    }
  }

  function getInsertion(x, y, pointId) {
    const projectPatch = projectStore.patch();
    let parentId, index, type;

    // Add grid rows if needed
    const currentRowCount = doc.grid[0] ? doc.grid[0].length : 0;
    const rowCount = Math.max(currentRowCount, y + 1);

    for (let y = currentRowCount; y < rowCount; y++) {
      for (let x = 0; x < doc.grid.length; x++) {
        projectPatch.patch.add(`/docs/${doc.id}/grid/${x}/${y}`, null);
      }
    }

    // Add additional columns if needed
    const currentColCount = doc.grid.length;
    const colCount = Math.max(currentColCount, x + 1);

    for (let x = currentColCount; x < colCount; x++) {
      parentId = projectStore.createDocId();
      const index = book ? x - 1 : x;
      projectPatch.createDoc({ id: parentId, type: 'novel_plot_line' }, doc.id, index, false);
      projectPatch.patch.add(`/docs/${doc.id}/grid/${x}`, [parentId].concat(new Array(rowCount - 1).fill(null)));
    }

    // Find the parentId, index, and default child type
    const prevItems = currentColCount < colCount ? [] : doc.grid[x].slice(1, y).filter(Boolean);
    const bookScenes = book && x === 0 && projectStore.getDoc(`${book.id}-scenes`);
    if (bookScenes) {
      type = 'novel_scene';
      let prev = prevItems.pop();
      if (prev === pointId) prevItems.pop();
      const currentIndexInBook = pointId && bookScenes.children.indexOf(pointId);

      if (pointId && prev && currentIndexInBook === prevItems.length + 1) {
        // no change
        const parent = projectStore.getParent(pointId);
        parentId = parent.id;
        index = parent.children.indexOf(pointId);
      } else if (prev) {
        const parent = projectStore.getParent(prev);
        parentId = parent.id;
        index = parent.children.indexOf(prev) + 1;
      } else {
        index = 0;
        parentId = findFirst(book, 'novel_chapter');
        if (!parentId) return;
      }
    } else {
      if (!parentId) {
        parentId = doc.grid[x][0];
        type = settings.getFor(projectStore.getDoc(parentId).type).defaultChildType;
      } else {
        type = 'novel_plot_point';
      }
      index = prevItems.length;
    }

    return [projectPatch, parentId, index, type];
  }

  function updateLinks(projectPatch, pointId, x, y, oldY = -1) {
    if (!book) return;
    if (x === 0) {
      for (let x = 1; x < doc.grid.length; x++) {
        if (oldY !== -1 && doc.grid[x][oldY]) projectPatch.unlinkDocs(doc.grid[x][oldY], 'plot', pointId);
        if (doc.grid[x][y]) projectPatch.linkDocs(doc.grid[x][y], 'plot', pointId);
      }
    } else if (oldY !== y) {
      if (oldY !== -1 && doc.grid[0][oldY]) projectPatch.unlinkDocs(pointId, 'plot', doc.grid[0][oldY]);
      if (doc.grid[0][y]) projectPatch.linkDocs(pointId, 'plot', doc.grid[0][y]);
    }
  }

  function createPoint(x, y) {
    const id = projectStore.createDocId();
    const [projectPatch, parentId, index, type] = getInsertion(x, y);

    // Add point or scene
    projectPatch.createDoc({ id, type }, parentId, index);

    // Set the plot/scene in the grid
    projectPatch.patch.replace(`/docs/${doc.id}/grid/${x}/${y}`, id);

    updateLinks(projectPatch, id, x, y);

    projectPatch.save();
  }

  function movePoint(point, x, y) {
    let oldY;
    let [projectPatch, parentId, index] = getInsertion(x, y, point.id);
    const oldParent = projectStore.getParent(point.id);
    const oldIndex = oldParent.children.indexOf(point.id);
    if (oldParent.id === parentId && oldIndex < index) {
      index--;
    }

    projectPatch.moveDoc(point.id, parentId, index);

    for (let x = 0; x < doc.grid.length; x++) {
      for (let y = 0; y < doc.grid[x].length; y++) {
        if (doc.grid[x][y] === point.id) {
          oldY = y;
          projectPatch.patch.replace(`/docs/${doc.id}/grid/${x}/${y}`, null);
        }
      }
    }

    projectPatch.patch.replace(`/docs/${doc.id}/grid/${x}/${y}`, point.id);
    updateLinks(projectPatch, point.id, x, y, oldY);

    projectPatch.save();
  }

  async function deletePoint(x, y) {
    const toDelete = projectStore.getDoc(doc.grid[x][y]);
    if (!toDelete) return;
    const title = getTitle(toDelete);

    const confirmMessage = toDelete.type === 'novel_scene' ? 'novel_delete_scene_confirm' : 'trash_confirm';

    if (isDocEmpty(toDelete) || (await confirm($t('send_to_trash'), $t(confirmMessage, { title })))) {
      const projectPatch = projectStore.patch().trashDoc(toDelete.id);
      projectPatch.patch.replace(`/docs/${doc.id}/grid/${x}/${y}`, null);
      projectPatch.save();
    }
  }

  function findFirst(doc, type) {
    if (doc.type === type) return doc.id;
    const children = projectStore.getChildren(doc.id) || [];
    for (let i = 0; i < children.length; i++) {
      const found = findFirst(children[i], type);
      if (found) return found;
    }
  }
</script>

<div data-id={doc.id} class="plot-grid" class:sticky-left={children[0] && children[0].type === 'novel_book_scenes'}>
  <div class="grid-header">
    <div class="plot-title">
      <EditableContent {doc} class="title" field="title" header="h1" />
    </div>
    <div class="line-titles">
      {#each children as line, x}
        {#if line.type === 'novel_book_scenes'}
          <div class="book-title">
            <h3>{settings.getPlaceholder(line)}</h3>
          </div>
        {:else}
          <div class="line-title">
            <EditableContent doc={line} class="title bottom" field="title" header="h3" />
            {#if !readonly}
              <button class="icon delete" on:click={() => deleteColumn(x)} use:tooltipTop={$t('novel_delete_column')}>
                <Icon path={mdiDelete} />
              </button>
            {/if}
          </div>
        {/if}
      {/each}

      {#if !readonly}
        <button
          class="icon new-plot-point-btn"
          on:click={() => createPoint(doc.grid.length, 1)}
          use:tooltipTop={$t('new_document', { type: settings.getFor(doc).defaultChildType })}
        >
          <Icon path={mdiPlusThick} />
        </button>
      {/if}
    </div>
  </div>

  <div class="plot-grid-body">
    {#if doc.grid}
      <div
        class="plot-grid-lines"
        style={highlight ? `background-position: 0 ${highlight.top}px, ${highlight.left}px 0` : ''}
      >
        {#if !readonly && doc.grid.length}
          <div class="plot-grid-actions">
            {#each doc.grid[0].slice(1) as row, y}
              <PlotRowActions
                {y}
                on:highlight={event => (highlight = event.detail)}
                visible={highlight && highlight.y === y}
                addTooltip={$t('novel_insert_row')}
                deleteTooltip={$t('novel_delete_row')}
                on:add={event => insertRow(y + 1)}
                on:delete={event => deleteRow(y + 1)}
              />
            {/each}
            <PlotRowActions
              y={doc.grid[0].length - 1}
              on:highlight={event => (highlight = event.detail)}
              visible={highlight && highlight.y === doc.grid[0].length - 1}
              addTooltip={$t('novel_insert_row')}
              on:add={event => insertRow(doc.grid[0].length)}
            />
          </div>
        {/if}

        {#each doc.grid as column, x}
          <PlotGridColumn
            column={column.slice(1)}
            doc={children[x] || emptyLine}
            on:highlight={event => (highlight = event.detail)}
            on:create={event => createPoint(x, event.detail.y)}
            on:delete={event => deletePoint(x, event.detail.y)}
            on:drop={event => movePoint(event.detail.doc, x, event.detail.y)}
            {readonly}
          />
        {/each}

        <PlotGridColumn
          column={emptyColumn}
          doc={emptyLine}
          on:highlight={event => (highlight = event.detail)}
          on:create={event => createPoint(doc.grid.length, event.detail.y)}
          on:drop={event => movePoint(event.detail.doc, doc.grid.length, event.detail.y)}
          {readonly}
        />

        <PlotGridColumn
          column={emptyColumn}
          doc={emptyLine}
          on:highlight={event => (highlight = event.detail)}
          on:create={event => createPoint(doc.grid.length + 1, event.detail.y)}
          on:drop={event => movePoint(event.detail.doc, doc.grid.length + 1, event.detail.y)}
          {readonly}
        />
      </div>
    {/if}
  </div>
</div>

<style>
  .plot-grid {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;
    width: max-content;
  }
  .plot-grid .grid-header {
    position: sticky;
    z-index: 3;
    top: -72px;
    width: 100%;
    background: var(--background);
    box-shadow: var(--top-down-shadow);
  }
  .plot-grid .plot-title {
    position: sticky;
    z-index: 3;
    left: 0px;
  }
  .plot-grid .plot-title :global(h1) {
    margin: 0;
    padding: 0.5rem 20px;
    color: var(--text-color-light);
    text-shadow: var(--text-highlight);
  }
  .plot-grid .line-titles {
    display: flex;
    background: linear-gradient(to bottom, var(--background), var(--background-darkened));
    padding: 0 20px;
  }

  .plot-grid .line-title {
    position: relative;
  }
  .plot-grid .line-title .icon.delete {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .plot-grid .line-title:hover .icon.delete {
    display: block;
  }
  .plot-grid .line-title .icon.delete:hover {
    color: var(--text-color-light);
  }
  .plot-grid .line-titles .book-title,
  .plot-grid .line-titles :global(.editable-content.title .typewriter-editor) {
    display: flex;
    flex-direction: column;
    width: calc(var(--card-width-sm) + 20px);
    padding: 0.25rem 10px 0;
  }
  .plot-grid .line-titles .book-title {
    position: sticky;
    margin-left: -20px;
    padding-left: 30px;
    width: calc(var(--card-width-sm) + 40px);
    left: 0;
    z-index: 1;
    background: linear-gradient(to bottom, var(--background), var(--background-darkened));
  }
  .plot-grid .line-titles .book-title h3,
  .plot-grid .line-titles :global(.editable-content.title h3) {
    flex: 1 1 auto;
    font-size: 18px;
    text-align: center;
    margin: 0 0 10px;
    padding: 0;
    color: var(--text-color-normal);
    text-shadow: var(--text-highlight);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .plot-grid .line-title :global(.placeholder) {
    opacity: 0.8;
  }
  .plot-grid .plot-grid-body {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    user-select: none;
  }
  .plot-grid .plot-grid-lines {
    display: flex;
    flex: 1;
    padding: 0 20px 0 0;
    min-height: 100%;
    background: linear-gradient(var(--plot-hover-highlight), var(--plot-hover-highlight)) no-repeat,
      linear-gradient(var(--plot-hover-highlight), var(--plot-hover-highlight)) no-repeat;
    background-size: 100% calc(var(--card-height-sm) + 20px), calc(var(--card-width-sm) + 20px) 100%;
    background-position: 0 calc(-1 * var(--card-height-sm) - 20px), calc(-1 * var(--card-width-sm) - 20px) 0;
  }
  .plot-grid-actions {
    padding: 5px 0;
    margin-right: -10px;
  }
  .sticky-left .plot-grid-actions {
    position: sticky;
    z-index: 2;
    left: 0;
  }
  .new-plot-point-btn {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    width: var(--card-width-sm);
    height: var(--card-height-sm);
    cursor: pointer;
    color: var(--text-color-lightest);
    font-size: 40px;
    line-height: 1;
    width: 40px;
    height: 40px;
  }
  .new-plot-point-btn:hover {
    color: var(--text-color-lighterer);
    text-shadow: var(--text-highlight);
  }
</style>
