<script>
  import Card from '@dabble/toolkit/Card.svelte';

  export let doc;
</script>

<div class="plot-point">
  <Card {doc} />
</div>

<style>
  .plot-point {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .plot-point :global(.notecard) {
    flex: 0 1 var(--card-width);
  }
</style>
