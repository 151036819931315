<script>
  import { t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiPlayCircle } from '@mdi/js';
  import { slide } from 'svelte/transition';
  import { sprint } from '../sprints';

  export let duration = parseInt(localStorage.lastDuration) || 15;

  async function createNewSprint() {
    sprint.start(duration);
  }
</script>

<div class="date-time" transition:slide|local={{ duration: 150 }}>
  <select class="form-control" bind:value={duration}>
    <option value={5}>5 minutes</option>
    <option value={10}>10 minutes</option>
    <option value={15}>15 minutes</option>
    <option value={20}>20 minutes</option>
    <option value={30}>30 minutes</option>
    <option value={45}>45 minutes</option>
    <option value={60}>1 hour</option>
  </select>
  <button class="icon" on:click={createNewSprint} use:tooltipTop={$t('timer_start_now')}>
    <Icon path={mdiPlayCircle} />
  </button>
  <!-- <p class="group-link">{@html $t('timer_group_link')}</p> -->
</div>

<style>
  .date-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .date-time {
    align-self: center;
  }
  .date-time .form-control {
    flex: 1 1;
  }
  .date-time .icon {
    font-size: 2rem;
  }
  .group-link {
    text-align: center;
  }
</style>
