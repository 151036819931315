import { plugins, router, userMeta } from '@dabble/app';
import { UserMetaStore, UserMetaTypeStore, createUserMetaTypeStore } from '@dabble/data/stores/user-meta';
import { UserMeta } from '@dabble/data/types';

export interface EntranceSurvey extends UserMeta {
  genre?: string;
  findUs?: string;
};

export function createEntranceSurveyStore(userMeta: UserMetaStore): UserMetaTypeStore<EntranceSurvey> {
  const { get, update, subscribe } = createUserMetaTypeStore<EntranceSurvey>(userMeta, 'survey-entrance');

  return {
    get,
    update,
    subscribe,
  };
}

export const entranceSurvey = createEntranceSurveyStore(userMeta);
plugins.register({ entranceSurvey });

window.addEventListener('signup', async () => {
  router.navigate('/survey/entrance', true);
});
