<script type="ts">
  import { t } from '@dabble/app';
  import rest from '@dabble/data/rest';
  import { currentUser } from '@dabble/plugins/account/accounts';
  import { createEventDispatcher } from 'svelte';
  import { entranceSurvey } from '../../entrance';
  const options = ['internet', 'social_media', 'referral', 'website', 'dabble_u', 'other'];
  const dispatch = createEventDispatcher();
  let showOther = false;
  let other = '';

  async function choose(option: string) {
    let value = option;
    switch (option) {
      case 'other':
        showOther = true;
        break;
      case 'send':
        value = other;
      // eslint-disable-next-line no-fallthrough
      default:
        entranceSurvey.update({ ...$entranceSurvey, joinUs: value });
        rest.POST('/analytics/survey', {
          email: $currentUser.email,
          attributes: { findUs: value },
        });
        dispatch('next-screen');
        break;
    }
  }
</script>

<section>
  <p>{$t('onboarding_find_us')}</p>
  <div class="genre-list">
    {#each options as option}
      <button class="btn large secondary genre-selection" on:click={() => choose(option)}>
        {@html $t(`onboarding_find_${option}`)}
      </button>
    {/each}
    {#if showOther}
      <input type="text" class="form-control" value={other} placeholder={$t('onboarding_other')} />
      <button class="btn large secondary genre-selection" on:click={() => choose('send')}>
        {@html $t(`onboarding_other_send`)}
      </button>
    {/if}
  </div>
</section>

<style>
  .genre-list {
    display: flex;
    flex-direction: column;
  }
  .genre-selection {
    margin-bottom: 8px;
  }
  .genre-list :global(small) {
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--text-color-lighter);
  }
</style>
