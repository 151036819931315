<script>
  import { t } from '@dabble/app';
  import FindUsQuestion from './entrance/FindUsQuestion.svelte';
  import GenreQuestion from './entrance/GenreQuestion.svelte';

  const screens = [FindUsQuestion, GenreQuestion];
  let step = 0;

  function nextScreen() {
    step += 1;
    if (step >= screens.length) {
      // survey done redirect to recent project page
      // genre question should be last it creates a project
      // of that genre
    }
  }
</script>

<svelte:head>
  <title>Dabble - {$t('onboarding_title')}</title>
</svelte:head>

<div class="signin-screen">
  <div class="signin-area">
    <div class="logo">Dabble</div>
    <div class="signin-form">
      <header>
        <h4>{$t('onboarding_title')}</h4>
      </header>
      <svelte:component this={screens[step]} on:next-screen={nextScreen} />
    </div>
  </div>
</div>

<style>
  .logo {
    background: url(~images/Dabble-Color-Logotype-Tagline-Padded.png) no-repeat center;
    background-size: contain;
    width: 384px;
    height: 100px;
    overflow: hidden;
    text-indent: -400px;
    margin: 0 auto 20px;
  }
  .genre-list {
    display: flex;
    flex-direction: column;
  }
  .genre-selection {
    margin-bottom: 8px;
  }
  .signin-screen {
    overflow: auto;
  }
  .signin-area {
    position: relative;
    max-width: 400px;
    margin: 32px auto;
  }
  .signin-form {
    padding: 10px;
    background-color: var(--white);
    box-shadow: var(--flat-box-shadow);
    line-height: normal;
  }
  @media only screen and (max-width: 416px) {
    .signin-area {
      width: auto;
      margin: 32px 8px;
    }
  }

  header,
  section {
    padding: 16px;
  }
  header {
    border-bottom: 4px solid #f5a503;
  }
  header h4 {
    color: #333;
    margin: 0;
  }
</style>
