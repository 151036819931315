<script>
  import {
    dailyGoalStore,
    features,
    getDateString,
    preferences,
    projectGoalStore,
    projectStore,
    shownModal,
    t,
    today,
    userStats,
  } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { number, percent } from '@dabble/toolkit/helpers';
  import { tooltipLeft, tooltipTop } from '@dabble/toolkit/tooltip';
  import { slide } from '@dabble/toolkit/transitions';
  import logging from '@dabble/util/log';
  import { mdiCog } from '@mdi/js';
  import { addDays, subDays } from 'date-fns';
  import {
    dailyGoal,
    daysLeft,
    last30For,
    target,
    todaysCount,
    todaysGoal,
    totalCount,
    totalGoal,
    totalTowardsDailyGoal,
  } from '../goals';
  import { forNaNoWriMo, nanowrimo } from '../nanowrimo';
  import RewardGoal from './RewardGoal.svelte';
  import ShareGoalsButton from './ShareGoalsButton.svelte';
  import TimerSidebar from './TimerSidebar.svelte';
  import WordcountGraph from './WordcountGraph.svelte';

  const log = logging.tagColor('NaNoWriMo', '#644835');

  let showGoals = true;
  let start;
  let dailyCounts;
  let max;
  let container;

  $: start = subDays($today, 29);
  $: dailyCounts = calculateDailyCounts($userStats, $last30For, $projectStore.projectId, $today, start);
  $: max = Math.max(...dailyCounts) * 1.2;

  function calculateDailyCounts(stats, last30For, projectId, today, start) {
    if (!stats || !projectId) return [];
    const forProject = last30For === 'project';
    const counts = [];
    let date = start;
    while (date <= today) {
      const target = forProject ? projectId : null;
      counts.push(userStats.getForDay(target, getDateString(date)));
      date = addDays(date, 1);
    }
    return counts;
  }

  function toggleLast30For() {
    preferences.update({ last30For: $last30For === 'project' ? 'all' : 'project' });
  }
</script>

<div class="goals-sidebar sidebar-section" bind:this={container}>
  <h3 class="section-header" class:closed={!showGoals}>
    {#if !$features.has('goals')}
      {$t('stats_title')}
    {:else if $forNaNoWriMo}
      {$t('goals_nanowrimo')}
    {:else}
      {$t('goals_and_stats_title')}
    {/if}
  </h3>

  {#if showGoals}
    <div class="body" transition:slide|local={{ duration: 150 }}>
      {#if $features.has('goals')}
        <div class="goal-info">
          {#if $target}
            <ShareGoalsButton {container} />
          {/if}
          <button
            class="icon cog"
            on:click={() => ($shownModal = 'goalSettings')}
            use:tooltipLeft={$t('goal_settings')}
          >
            <Icon path={mdiCog} />
          </button>
          <div class="content">
            <RewardGoal />
            {#if $dailyGoalStore.end}
              <div class="goal user-goal">
                <div class="goal-title">
                  {$t('daily_goal_title')}
                  <div class="counts">
                    <span class="daily-count">{number($totalTowardsDailyGoal) + ' / '}</span><span class="daily-goal"
                      >{number($dailyGoal)}</span
                    >
                  </div>
                </div>
                <div
                  class="goal-progress-chart"
                  use:tooltipTop={$t('goals_todays_progress', {
                    percent: percent(Math.min(1, $totalTowardsDailyGoal / $dailyGoal)),
                  })}
                >
                  <div
                    class="goal-progress"
                    style="width: {percent(Math.min(1, $totalTowardsDailyGoal / $dailyGoal))}"
                  />
                </div>
              </div>
            {/if}
            {#if $target && $projectGoalStore.deadline}
              <div class="goal">
                <div class="goal-title">{$t('project_goal_title')}</div>
                <div class="todays-info">
                  {#if $forNaNoWriMo}
                    <a
                      class="nano-link"
                      class:winner={$nanowrimo.won}
                      href="https://nanowrimo.org/"
                      target="_blank"
                      use:tooltipTop={$t('goals_goto_nanowrimo')}
                    >
                      <div class="nano-logo" />
                    </a>
                  {/if}
                  <div class="line-group">
                    <div class="line">
                      <div class="left cell bold">{number($todaysCount)}</div>
                      <div class="right cell">{$t('goals_written_today')}</div>
                    </div>
                    <div class="line">
                      <div class="left cell bold">{number($todaysGoal)}</div>
                      <div class="right cell">{$t('goals_todays_goal')}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="goal-progress-chart"
                  use:tooltipTop={$t('goals_todays_progress', {
                    percent: percent(Math.min(1, $todaysCount / $todaysGoal)),
                  })}
                >
                  <div class="goal-progress" style="width: {percent(Math.min(1, $todaysCount / $todaysGoal))}" />
                </div>
              </div>
            {/if}
            {#if $target}
              <div class="goal">
                {#if !$projectGoalStore.deadline}
                  <div class="goal-title">{$t('project_goal_title')}</div>
                {/if}
                <div class="total-info">
                  {#if $target && $projectGoalStore.deadline}
                    <div class="line">
                      <div class="left cell">{number($daysLeft)}</div>
                      <div class="right cell">{$t('goals_days_left')}</div>
                    </div>
                  {/if}
                  <div class="line">
                    <div class="left cell">{number($totalCount)}</div>
                    <div class="right cell">{$t('goals_written')}</div>
                  </div>
                  <div class="line">
                    <div class="left cell">{number(Math.max(0, $totalGoal - $totalCount))}</div>
                    <div class="right cell">{$t('goals_remaining')}</div>
                  </div>
                  <div class="line">
                    <div class="left cell">{number($totalGoal)}</div>
                    <div class="right cell">{$t('goals_goal')}</div>
                  </div>
                </div>
                <div
                  class="goal-progress-chart"
                  style="margin-bottom: 6px"
                  use:tooltipTop={$t('goals_total_progress', {
                    percent: percent(Math.min(1, $totalCount / $totalGoal)),
                  })}
                >
                  <div class="goal-progress" style="width: {percent(Math.min(1, $totalCount / $totalGoal))}" />
                </div>
              </div>
            {/if}
          </div>
        </div>
      {/if}
      <h6
        use:tooltipTop={$t('last30_for_tooltip_' + $last30For)}
        on:click={toggleLast30For}
        on:keydown={toggleLast30For}
        class="stat-label"
      >
        {$t('last30_for_title_' + $last30For)}
      </h6>
      <WordcountGraph days={dailyCounts} startDay={start} {max} />
    </div>
  {/if}
  <TimerSidebar />
</div>

<style>
  .goals-sidebar {
    font-size: var(--font-size-xs);
    line-height: 20px;
    background-color: var(--side-nav-background);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .goals-sidebar .stat-label {
    cursor: pointer;
  }
  .goals-sidebar .icon.cog {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .goals-sidebar .goal-title {
    display: grid;
    grid-template-columns: 1fr 10px auto;
    width: 100%;
    font-size: var(--font-size-sm);
    text-transform: uppercase;
    font-weight: bold;
  }
  .goals-sidebar .goal.user-goal {
    margin-bottom: 20px;
  }
  .goals-sidebar .goal-title .counts {
    grid-column: 3;
    display: flex;
  }
  .goals-sidebar .goal-title .counts .daily-count {
    font-weight: normal;
  }

  /* .goals-sidebar .icon.share {
  position: absolute;
  right: 34px;
  top: 10px;
} */
  .goals-sidebar .line-group {
    flex: 1;
  }
  .goals-sidebar .line {
    display: flex;
  }
  .goals-sidebar .cell {
    width: 50%;
    white-space: nowrap;
  }
  .goals-sidebar .right.cell {
    width: 88px;
  }
  .goals-sidebar .left.cell {
    flex: 1;
  }
  .goals-sidebar .cell.bold {
    font-weight: bold;
  }
  .goals-sidebar .cell:first-child {
    text-align: right;
    padding-right: 10px;
  }
  .goals-sidebar .todays-info,
  .goals-sidebar .total-info {
    padding: 4px 0;
  }
  .todays-info {
    display: flex;
  }
  .goals-sidebar .goal-progress-chart {
    position: relative;
    height: 15px;
    padding: 5px 0;
  }
  .goals-sidebar .goal-progress-chart::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: var(--side-nav-color-light);
  }
  .goals-sidebar .goal-progress-chart .goal-progress {
    position: absolute;
    background-color: var(--dabble-blue);
    height: 5px;
    width: 0;
  }
  .goals-sidebar .goal-progress-chart:hover::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
  .goal-info {
    display: flex;
  }
  .content {
    flex: 1 1 auto;
    margin-top: 10px;
  }
  .nano-link {
    width: 50px;
    height: 50px;
    margin: 0px 10px 0 0;
    flex: 0 0 auto;
  }
  .nano-logo {
    background: url(../images/badge.png) no-repeat;
    width: 60px;
    height: 60px;
    background-size: 100% 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }
  h6 {
    font-family: inherit;
    font-weight: bold;
    color: #999;
    text-align: center;
    font-size: var(--font-size-xs);
    margin: 10px 0 5px;
    text-transform: uppercase;
  }
</style>
