<script lang="ts">
  import {
    dailyGoalStore,
    dateOptions,
    daysOffStore,
    getDateString,
    getTitle,
    parseDateString,
    projectGoalStore,
    projectStore,
    shownModal,
    t,
    today,
    todaysDate,
    userStats,
  } from '@dabble/app';
  import { UserGoalStore } from '@dabble/data/stores/user-goal';
  import { UserGoal } from '@dabble/data/types';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import CheckButton from '@dabble/toolkit/CheckButton.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { autoselect } from '@dabble/toolkit/autoselect';
  import { number, parseInteger } from '@dabble/toolkit/helpers';
  import { format, setDay, subDays } from 'date-fns';
  import { nanowrimo, nanowrimoIsNow, nanowrimoParticipating } from '../nanowrimo';
  import Calendar from './Calendar.svelte';

  const MODAL_NAME = 'goalSettings';
  const GOAL_TAB = 'goals';
  const DAILY_GOAL_TAB = 'dailyGoals';
  const DAYS_OFF_TAB = 'daysOff';
  const DEFAULT_END = 50000;
  let forNaNoWriMo = false;
  let screen = GOAL_TAB;
  let projectGoal: UserGoal;
  let dailyGoal: UserGoal;
  let daysOff: UserGoal;
  let error: string;
  let dailyGoalUpdated = false;
  let projectGoalUpdated = false;
  let daysOffUpdated = false;
  let lastProjectId: string;

  $: forNaNoWriMo = $nanowrimoParticipating && $nanowrimo.projectId === $projectStore.projectId;
  $: {
    if ($shownModal === MODAL_NAME) {
      screen = GOAL_TAB;
      const modified = 0;
      projectGoal = { projectId: $projectStore.projectId, end: DEFAULT_END, modified, ...$projectGoalStore };
      dailyGoal = $dailyGoalStore || { projectId: 'dailyGoal', modified, ...$dailyGoalStore };
      daysOff = $daysOffStore || { projectId: 'daysOff', modified, ...$daysOffStore };
      error = null;
    }
  }

  async function saveGoal(goal: UserGoal, goalStore: UserGoalStore) {
    error = null;
    try {
      let deleteIt = false;
      $shownModal = null;
      let todaysCount = 0;
      switch (goal.projectId) {
        case 'daily':
          deleteIt = !goal.end;
          if (deleteIt) break;
          try {
            goal.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          } catch (err) {
            console.error(err);
          }
          dailyGoalUpdated = false;
          todaysCount = userStats.getForDay(null, $todaysDate);
          // For dailyGoals only, if there is no lastWin, set it to yesterday.
          // If lastWin is today, but the goal has changed to be greater than today's count
          // set the lastWin to yesterday, so that if they meet the new goal today, it will celebrate again.
          if (!goal.lastWin || (goal.lastWin >= $todaysDate && todaysCount < goal.end)) {
            goal.lastWin = getDateString(subDays($today, 1));
          }
          break;
        case 'daysOff':
          daysOffUpdated = false;
          break;
        default:
          deleteIt = !goal.targetId || !goal.end;
          projectGoalUpdated = false;
          break;
      }
      if (deleteIt) await goalStore.delete(goal.projectId);
      else await goalStore.update(goal);
    } catch (err) {
      console.error(err);
      error = 'An error occurred:' + err.message;
    }
  }

  function updateGoal(goal: UserGoal, field: string, value: any) {
    switch (goal.projectId) {
      case 'daily':
        dailyGoalUpdated = true;
        break;
      case 'daysOff':
        daysOffUpdated = true;
        break;
      default:
        projectGoalUpdated = true;
        break;
    }
    return { ...goal, [field]: value };
  }

  function toggleProjectGoal() {
    let targetId: string;
    if (projectGoal.targetId) {
      lastProjectId = projectGoal.targetId;
      targetId = null;
    } else {
      targetId = lastProjectId || $projectStore.childrenLookup[$projectStore.project.children[0]][0].id;
    }
    projectGoal = { ...projectGoal, targetId };
    projectGoalUpdated = true;
  }
</script>

{#if $shownModal === MODAL_NAME && $projectStore.projectId}
  <Modal title={$t('goal_settings')} on:close={() => ($shownModal = null)}>
    {#if error}
      <Alert type="danger" dismissible>
        <strong>{$t('error')}</strong>
        {error}
      </Alert>
    {/if}
    <ul class="nav tabs">
      <li class:active={screen === GOAL_TAB}>
        <button on:click={() => (screen = GOAL_TAB)} class="btn link">{$t('goals_goal')}</button>
      </li>
      <li class:active={screen === DAILY_GOAL_TAB}>
        <button on:click={() => (screen = DAILY_GOAL_TAB)} class="btn link">{$t('goals_daily_goal')}</button>
      </li>
      <li class:active={screen === DAYS_OFF_TAB}>
        <button on:click={() => (screen = DAYS_OFF_TAB)} class="btn link">{$t('goals_days_off')}</button>
      </li>
    </ul>

    {#if screen === GOAL_TAB}
      <section class="goals-settings-main">
        <div class="form-group">
          <label class="section-header">
            <input type="checkbox" on:change={toggleProjectGoal} checked={!!projectGoal.targetId} />
            {$t('goals_goal')}
          </label>
        </div>
        <div class="section-flex" class:goal-disabled={!projectGoal.targetId}>
          {#if forNaNoWriMo}
            <div class="nano-logo" class:winner={$nanowrimo.won} />
          {/if}
          <div class="form">
            <div class="section-header">{$t('goals_word_count_source')}</div>
            <div class="form-group">
              <select
                class="form-control"
                value={projectGoal.targetId}
                on:change={event => (projectGoal = updateGoal(projectGoal, 'targetId', event.target.value))}
              >
                <option value={$projectStore.projectId}>Project</option>
                <option value={$projectStore.project.children[0]}>&nbsp;&nbsp;{$t('novel_manuscript')}</option>
                {#if $projectStore.childrenLookup[$projectStore.project.children[0]]}
                  {#each $projectStore.childrenLookup[$projectStore.project.children[0]] as book (book.id)}
                    <option value={book.id}>&nbsp;&nbsp;&nbsp;&nbsp;{$t('goals_book')} “{getTitle(book)}”</option>
                  {/each}
                {/if}
              </select>
              <div class="section-note">{$t('goals_goal_tracking')}</div>
            </div>

            <div class="section-header">{$t('goals_set_goal')}</div>
            <div class="form-group">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  use:autoselect
                  value={number(projectGoal.end) || ''}
                  on:input={event => (projectGoal = updateGoal(projectGoal, 'end', parseInteger(event.target.value)))}
                />
                <span class="input-group-addon">{$t('goals_words')}</span>
              </div>
              <div class="section-note">{$t('goals_word_count_goal')}</div>
            </div>

            <div class="section-header">{$t('goals_exclude_word_count')}</div>
            <div class="form-group">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  use:autoselect
                  value={number(projectGoal.start) || 0}
                  on:input={event => (projectGoal = updateGoal(projectGoal, 'start', parseInteger(event.target.value)))}
                />
                <span class="input-group-addon">{$t('goals_words')}</span>
              </div>
              <div class="section-note">{$t('goals_zero_sum')}</div>
            </div>
          </div>

          {#if !forNaNoWriMo}
            <div class="calendar">
              <div class="section-header">{$t('goals_choose_deadline')}</div>
              <Calendar
                selected={parseDateString(projectGoal.deadline)}
                on:select={event =>
                  (projectGoal = updateGoal(projectGoal, 'deadline', getDateString(event.detail.selected)))}
              />
              <div class="section-note">{$t('goals_select_date')}}</div>
            </div>
          {/if}
        </div>
      </section>
    {/if}

    {#if screen === DAILY_GOAL_TAB}
      <section class="goals-settings-main">
        <div class="section-flex">
          <div class="form">
            <div class="section-header">{$t('goals_daily_goal_caption')}</div>
            <div class="section-description">{$t('goals_daily_goal_explanation')}</div>
            <div class="form-group">
              <div class="input-group input-group-half-size">
                <input
                  type="text"
                  class="form-control"
                  use:autoselect
                  value={number(dailyGoal.end) || ''}
                  on:input={event => {
                    dailyGoal = updateGoal(dailyGoal, 'end', parseInteger(event.target.value));
                  }}
                />
                <span class="input-group-addon">{$t('goals_words')}</span>
              </div>
              <div class="days-off-checkbox">
                <CheckButton
                  on:click={() => (dailyGoal = updateGoal(dailyGoal, 'sendReminder', !dailyGoal.sendReminder))}
                  checked={dailyGoal.sendReminder}
                >
                  {$t('goals_daily_goal_send_email_reminder')}
                </CheckButton>
                <div class="goals-settings-email-reminder-explanation">
                  {$t('goals_daily_goal_send_email_explanation')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/if}

    {#if screen === DAYS_OFF_TAB}
      <section class="goals-settings-main">
        <div class="section-header">{$t('goals_pick_days_off')}</div>
        <div class="section-flex">
          <Calendar
            daysOff={daysOff.daysOff || {}}
            on:change={event => (daysOff = updateGoal(daysOff, 'daysOff', event.detail.daysOff))}
          />
          <div class="section-note">
            <p style="margin-top:0">{$t('goals_turn_off_days')}</p>
            {@html $t('goals_example', {
              date_to_click_string: format(setDay(new Date(), 3, dateOptions), 'EEE', dateOptions),
              date_to_click: format(setDay(new Date(), 3, dateOptions), 'EEEE', dateOptions),
            })}
          </div>
        </div>
      </section>
    {/if}

    <footer>
      {#if $nanowrimoIsNow && $nanowrimoParticipating && $nanowrimo.projectId === $projectStore.projectId}
        <button class="btn secondary left" on:click={() => ($shownModal = 'nanowrimoSettings')}
          >{$t('settings_nanowrimo')}</button
        >
      {/if}
      <button
        class="btn primary"
        on:click={() => {
          projectGoalUpdated && saveGoal(projectGoal, projectGoalStore);
          dailyGoalUpdated && saveGoal(dailyGoal, dailyGoalStore);
          daysOffUpdated && saveGoal(daysOff, daysOffStore);
        }}
        disabled={!dailyGoalUpdated && !projectGoalUpdated && !daysOffUpdated}>{$t('goals_save_changes')}</button
      >
    </footer>
  </Modal>
{/if}

<style>
  .section-header {
    font-weight: bold;
    margin-bottom: 6px;
    color: var(--text-color-normal);
  }
  .section-description {
    margin-top: 4px;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    margin-bottom: 22px;
    margin-right: 30px;
  }
  .input-group-half-size {
    width: 50%;
  }
  .section-note {
    margin-top: 4px;
    font-size: var(--font-size-xs);
    color: var(--text-color-lighter);
  }
  .section-flex {
    display: flex;
  }
  .section-flex > :not(:first-child) {
    margin-left: 40px;
  }
  .goal-disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
  :global(.size-mobile) .section-flex {
    flex-direction: column;
  }
  :global(.size-mobile) .section-flex > :not(:first-child) {
    margin-left: 0;
    margin-top: 40px;
  }
  .nano-logo {
    background: url(../images/badge.png) no-repeat;
    width: 180px;
    height: 180px;
    background-size: 100% 100%;
    flex: 0 0 auto;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }
  .days-off-checkbox {
    margin-top: 30px;
    width: 50%;
  }

  .days-off-checkbox :global(.btn) {
    width: 100%;
  }

  .goals-settings-email-reminder-explanation {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    margin-top: 10px;
    margin-left: 5px;
  }
</style>
