<script>
  import { inform, shownModal, t } from '@dabble/app';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { format } from 'date-fns';
  import { isCamp, monthOf, nanowrimo, nanowrimoPrepTime } from '../nanowrimo';

  const title = $isCamp ? 'Camp NaNoWriMo' : 'National Novel Writing Month';

  function closeModal() {
    $shownModal = null;
  }

  async function decline() {
    closeModal();
    await nanowrimo.update({ participating: false });
    inform('info', $t('nanowrimo_maybe_later'), $t('nanowrimo_turn_on_later'));
  }

  async function showSettings() {
    closeModal();
    await nanowrimo.update({ participating: true });
    $shownModal = 'nanowrimoSettings';
  }
</script>

{#if $shownModal === 'nanowrimoInvite'}
  <Modal
    title={$t('nanowrimo_title', {
      nanowrimo_month: format($monthOf, 'MMMM'),
      nanowrimo_preptime: $nanowrimoPrepTime ? ' Almost' : '',
    })}
    on:close={decline}
    size="large"
  >
    <section class="nano-invite-main">
      <div class="nano-logo" />
      <div class="nano-invite-content">
        <h3>{title}</h3>
        <p>{@html $t('nanowrimo_join_thousands')}</p>
        <p>
          {@html $t('nanowrimo_is_camp', { nanowrimo_project_title: title, nanowrimo_month: format($monthOf, 'MMMM') })}
        </p>

        <h4>{@html $t('nanowrimo_integration')}</h4>
        <p>{@html $t('nanowrimo_dabble_will')}</p>
        <ul>
          <li>{@html $t('nanowrimo_sync_word_count')}</li>
          <li>{@html $t('nanowrimo_track_your_progress')}</li>
          <li>{@html $t('nanowrimo_daily_word_count', { nanowrimo_month: format($monthOf, 'MMMM') })}</li>
        </ul>
      </div>
    </section>
    <footer>
      <button class="btn secondary" on:click={decline}>{$t('nanowrimo_not_interested')}</button>
      <button class="btn primary" on:click={showSettings}>{$t('nanowrimo_authenticate')}</button>
    </footer>
  </Modal>
{/if}

<style>
  .nano-invite-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nano-logo {
    background: url(../images/badge.png) no-repeat;
    width: 150px;
    height: 150px;
    background-size: 100% 100%;
    flex: 0 0 auto;
    margin-bottom: 10px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  }
  .nano-invite-content {
    flex: 1 1 auto;
  }
  .nano-invite-content h3 {
    margin-top: 10px;
  }
  @media (min-width: 992px) {
    .nano-invite-main {
      flex-direction: row;
      align-items: flex-start;
    }
    .nano-logo {
      width: 300px;
      height: 300px;
      margin-right: 20px;
    }
  }
</style>
