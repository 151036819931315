import { FeaturesStore } from '@dabble/data/stores/features';
import { CurrentProjectMetaStore } from '@dabble/data/stores/project-metas';
import { ReadOnlyStore } from '@dabble/data/stores/readonly';
import { Readable, derived, readable } from '@dabble/data/stores/store';
import { ProjectMeta } from '@dabble/data/types';
import { createInViewportStore } from '@dabble/toolkit/viewport';
import { Editor, isEqual } from 'typewriter-editor';
import { allowedChecks } from './model';

export interface AllowedChecks {
  spelling: boolean;
  grammar: boolean;
}

export interface GrammarSettings extends AllowedChecks {
  language: string;
}

/**
 * Store for tracking whether the editor is enabled or not.
 */
export function createEnabledStore(editor: Editor) {
  return readable(editor.enabled, set => {
    const onChange = () => set(editor.enabled);
    editor.on('enabledchange', onChange);
    return () => editor.off('enabledchange', onChange);
  });
}

/**
 * Store for tracking whether the editor show show any grammar/spelling issues.
 */
export function createShowGrammarStore(editor: Editor) {
  // Once in the viewport, it should always show if turned on (don't turn on/off based on scrolling)
  let showGrammar = false;
  const inViewportStore = createInViewportStore(editor.root);
  const enabledStore = createEnabledStore(editor);
  return derived([inViewportStore, enabledStore, allowedChecks], ([inViewport, enabled, allowed]) => {
    if (!enabled || (!allowed.spelling && !allowed.grammar)) return false;
    if (showGrammar) return true;
    if (inViewport) return (showGrammar = true); // Once it enters the viewport, it should always show
  });
}

export function createAllowedChecksStore(
  currentProjectMeta: CurrentProjectMetaStore,
  features: FeaturesStore,
  readonly: ReadOnlyStore
) {
  let prior: AllowedChecks = { spelling: true, grammar: false };

  return derived([currentProjectMeta, features, readonly], ([$currentProjectMeta, $features, $readonly]) => {
    const { spellingCheck, grammarCheck } = $currentProjectMeta || ({} as ProjectMeta);
    const spelling = !$readonly && $features.has('spell_check') && spellingCheck !== false;
    const grammar = (!$readonly && $features.has('grammar') && grammarCheck) || false;
    if (spelling === prior.spelling && grammar === prior.grammar) return prior;
    return (prior = { spelling, grammar } as AllowedChecks);
  });
}

export function createSpellingLanguageStore(currentProjectMeta: CurrentProjectMetaStore, locale: Readable<string>) {
  return derived([currentProjectMeta, locale], ([$currentProjectMeta, $locale]) => {
    return $currentProjectMeta?.spellingLanguage || $locale;
  });
}

export function createGrammarSettingsStore(allowed: Readable<AllowedChecks>, language: Readable<string>) {
  let prior: GrammarSettings = { spelling: false, grammar: false, language: '' };

  return derived([allowed, language], ([allowed, language]) => {
    const value = {
      spelling: allowed.spelling,
      grammar: allowed.grammar,
      language,
    };
    if (!isEqual(value, prior)) {
      prior = value;
    }
    return prior;
  });
}
