<script>
  import { shownModal, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCog } from '@mdi/js';
  import { nanowrimoParticipating, nanowrimoTrialTime } from '../nanowrimo';

  function openSettings() {
    if ($nanowrimoParticipating) {
      $shownModal = 'nanowrimoSettings';
    } else {
      $shownModal = 'nanowrimoInvite';
    }
  }
</script>

{#if $nanowrimoTrialTime}
  <button class="dropdown-item" on:click={openSettings}>
    <Icon path={mdiCog} />{$t('settings_nanowrimo')}
  </button>
{/if}
