import {
  collective,
  connected,
  danger,
  delegate,
  desktop,
  load as loadDb,
  plugins,
  router,
  t,
  unload as unloadDb,
  userProjects,
} from '@dabble/app';
import { ACCOUNT_URL } from '@dabble/data/global-constants';
import { observe } from '@dabble/data/observe';
import rest from '@dabble/data/rest';
import { User } from '@dabble/data/types';
import { connectionStore } from '@dabble/plugins/sync/active-connection';
import * as auth from './auth';

export const uid = auth.uid;
export const masterUser = auth.masterUser;
export const currentUser = auth.currentUser;
export const accounts = auth.accounts;

type Unsubscribe = () => void;

const unsubscribes: Unsubscribe[] = [];
let redirect: string = null;
let lastAuth: string;
let signingInForUid = getSignInFor(router.getQuery().get('token'));

auth.load().then(async () => {
  const token = router.getQuery().get('token');
  const created = router.getQuery().has('created');
  if (!token && !created) return;
  await router.navigate(router.get().url.replace(/\?.*/, ''), true);
  if (created) window.dispatchEvent(new CustomEvent('signup'));
  if (token) await auth.signInWithCustomToken(token);
});
plugins.register({ uid, masterUser, currentUser, accounts });

export async function startAuth(switchAccount = false) {
  // Added to allow for switching accounts
  try {
    await rest.DELETE('/auth/signIn');
  } catch (e) {}

  redirect = router.getUrl();
  if (redirect.startsWith('/auth/')) redirect = '/';

  if (desktop.inApp) {
    router.navigate(`/auth/${localStorage.getItem('signedInBefore') ? 'signin' : 'signup'}`, true);
  } else {
    // redirects to accounts.dabblewriter.com
    const query = [];
    let queryString = '';

    const loc = new URL(window.location.href);
    if (
      loc.hostname === 'localhost' ||
      loc.hostname.endsWith('dabblewriter.com') ||
      loc.hostname.endsWith('dabble-writer.pages.dev')
    )
      query.push(`redirect=${loc.protocol}//${loc.host}`);
    if (switchAccount) query.push('switch=1');
    if (query.length > 0) {
      queryString = `?${query.join('&')}`;
    }
    window.location.href = `${ACCOUNT_URL}auth/${
      localStorage.getItem('signedInBefore') ? 'login' : 'signup'
    }${queryString}`;
  }
}

export async function signIn(email: string, password: string) {
  const user = await auth.signIn(email, password);
  window.dispatchEvent(new CustomEvent('signin', { detail: { uid: user.uid, name: user.name, email } }));
  redirectAfterAuthChange();
  return user;
}

export async function signUp(name: string, email: string, password: string) {
  try {
    const user = await auth.signUp(name, email, password);
    window.dispatchEvent(new CustomEvent('signup', { detail: { uid: user.uid, name, email } }));
    return user;
  } catch (err) {
    if (err.code === 'auth/email-already-in-use') {
      // try to sign in if signup was used by mistake
      return signIn(email, password).catch(() => Promise.reject(err));
    }
    return Promise.reject(err);
  }
}

export async function signOut() {
  userProjects.get().map(p => p.projectId);
  const localCount = (
    await Promise.all(
      userProjects.get().map(({ projectId }) => {
        return collective.store.getChange(projectId, { committed: false });
      })
    )
  ).filter(Boolean).length;

  if (localCount) {
    const stop = !(await danger(t.get()('confirm_header_unsynced_data'), t.get()('confirm_unsynced_data'), {
      yesNo: true,
    }));
    if (stop) return;
  }

  unloadDb(true, uid.get());
  removeDelegate();
  await auth.signOut();
}

export async function updateUser(updates: auth.UserUpdates) {
  const current = currentUser.get();
  updates = { ...updates };

  (Object.keys(updates) as Array<keyof typeof updates>).forEach(key => {
    if (!updates[key] || (key !== 'password' && updates[key] === current[key])) delete updates[key];
  });

  if (!Object.keys(updates).length) return current;
  return await auth.updateUser(updates);
}

export async function switchTo(user: User) {
  await auth.switchTo(user);
  redirectAfterAuthChange(true);
}

export function requestPasswordReset(email: string) {
  return auth.requestPasswordReset(email);
}

export function load() {
  let lastUid: string;

  unsubscribes.push(
    uid.subscribe(uid => {
      if (lastUid === uid || (signingInForUid && signingInForUid !== uid)) return;

      if (lastUid) {
        unloadDb();
      }

      if (uid) {
        if (signingInForUid) signingInForUid = null;
        if (!localStorage.signedInBefore) localStorage.setItem('signedInBefore', '1');
        if (lastUid) {
          redirectAfterAuthChange(true);
        } else {
          loadDb(uid);
          addDelegate();
          redirectAfterAuthChange();
          const { name, email } = currentUser.get();
          window.dispatchEvent(new CustomEvent('authed', { detail: { uid, name, email } }));
        }
      } else {
        if (delegate) {
          sessionStorage.removeItem('delegate');
          window.close();
          return;
        }
        if (lastUid) {
          location.reload();
        } else if (!router.getUrl().startsWith('/auth/') && !router.getQuery().get('token')) {
          startAuth();
        }
      }

      lastUid = uid;
    })
  );
}

export function unload() {
  unsubscribes.forEach(unsubscribe => unsubscribe());
}

async function redirectAfterAuthChange(switchedAccount?: boolean) {
  if (switchedAccount) {
    if (router.getUrl().endsWith('/signup')) {
      await router.navigate('/survey/entrance', true);
      location.reload();
    } else {
      await router.navigate('/', true);
      location.reload();
    }
  } else if (router.getUrl().startsWith('/auth/')) {
    await router.navigate(redirect || '/', true);
  }
  redirect = null;
}

observe([connectionStore, connected, masterUser], async ([connection, connected, user]) => {
  const last = lastAuth;
  connected = (connected && connection?.state.get().connected) || false;
  lastAuth = `${connected}:${user && user.uid}`;
  if (!connection || lastAuth === last || !connected) return;
  if (user && user.uid !== last) {
    try {
      const tokens = await auth.getIdTokens();
      await connection.auth(tokens);
    } catch (err) {
      if (err.message === 'TOKEN_EXPIRED' || err.message === 'INVALID_TOKENS') {
        startAuth();
        await auth.signOut(true);
        location.reload();
      }
    }
  } else if (!user && last) {
    connection.auth(); // send empty auth to clear it
  }
});

function addDelegate() {
  if (!delegate) return;
  const { uid, name, email } = currentUser.get();
  removeDelegate(); // Ensure it isn't duplicated, and push it to the end of the array
  localStorage.delegates = ((localStorage.delegates as string) || '')
    .split('|')
    .concat(`${uid},${name},${email}`)
    .join('|');
}

function removeDelegate() {
  if (!delegate) return;
  delete localStorage[`${delegate}-election-leader`];
  delete localStorage[`${delegate}-election-tabs`];
  localStorage.delegates = ((localStorage.delegates as string) || '')
    .split('|')
    .filter(user => user.split(',')[0] !== delegate)
    .join('|');
}

function getSignInFor(token: string): string {
  if (!token) return null;
  try {
    return JSON.parse(atob(token.split('.')[1])).uid;
  } catch (err) {
    console.error(err);
  }
  return null;
}
