<script lang="ts">
  import { connectionState, currentProjectMeta, isLeader, projectStore, t } from '@dabble/app';
  import { Connection } from '@dabble/data/connection';
  import { ERROR, PENDING, SAVED, SAVING, SaveStatus } from '@dabble/data/stores/project';
  import { Project } from '@dabble/data/types';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiContentSaveEdit } from '@dabble/toolkit/custom-icons';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { escape, br } from '@dabble/toolkit/helpers';
  import {
    mdiClose,
    mdiCloud,
    mdiCloudAlert,
    mdiCloudArrowUp,
    mdiCloudCheck,
    mdiCloudOff,
    mdiCloudSync,
    mdiContentSaveAlert,
    mdiContentSaveCheck,
  } from '@mdi/js';
  import { syncState } from '../syncing';

  export let showOne = false;
  let showErrorMessage = false;

  const saveStatus = projectStore.status;
  const state = connectionState;

  $: localPath = getIconPath($saveStatus);
  $: cloudIcon = getCloudIcon($state, $projectStore.project, $syncState);
  $: localMessage = getLocalMessage($saveStatus);
  $: cloudMessage = getCloudMessage($state, $projectStore.project, $syncState);
  $: hasError = $saveStatus === 'error' || $syncState instanceof Error;
  $: showErrorMessage = hasError;

  function getIconPath(saveStatus: SaveStatus) {
    if (saveStatus === ERROR) return mdiContentSaveAlert;
    if (saveStatus === PENDING) return mdiContentSaveEdit;
    if (saveStatus === SAVING) return mdiContentSaveEdit;
    if (saveStatus === SAVED) return mdiContentSaveCheck;
  }

  function getCloudIcon(state: Connection, project: Project, syncing: boolean | Error) {
    const unsynced = project && (!$currentProjectMeta || !$currentProjectMeta.committed || !$currentProjectMeta.roles);
    if (state.authed) {
      if (syncing instanceof Error) return mdiCloudAlert;
      if (syncing) return mdiCloudSync;
      if (unsynced) return mdiCloudArrowUp;
      return mdiCloudCheck;
    } else {
      return mdiCloudOff;
    }
  }

  function getLocalMessage(saveStatus: SaveStatus) {
    return $t('save_' + saveStatus);
  }

  function getCloudMessage(state: Connection, project: Project, syncing: boolean | Error) {
    const unsynced = project && (!$currentProjectMeta || !$currentProjectMeta.committed || !$currentProjectMeta.roles);
    if (state.authed) {
      if (syncing instanceof Error) return $t('syncing_error', { error: syncing.message });
      if (unsynced) return $t('syncing_off');
      if (syncing) return $t('syncing_syncing');
      return $t('syncing_complete');
    } else {
      return $t('syncing_offline');
    }
  }
</script>

<div class="sync-status">
  {#if !showOne || !$state.authed}
    <div class="status-icon" use:tooltipBottom={localMessage}>
      <Icon path={localPath} />
    </div>
  {/if}
  {#if !showOne}
    <div class="separator" />
  {/if}
  {#if !showOne || $state.authed}
    <div class="status-icon" use:tooltipBottom={cloudMessage}>
      <Icon
        path={cloudIcon === mdiCloudSync ? mdiCloud : cloudIcon}
        overlay={cloudIcon === mdiCloudSync ? 'cloudSync' : undefined}
        spin={cloudIcon === mdiCloudSync}
      />
    </div>
  {/if}
  {#if showErrorMessage}
    <Dropdown placement="top-start" arrow offset="0 8" noFocus={true}>
      <div class="error-box">
        <button class="icon close-btn" on:click={() => (showErrorMessage = false)}><Icon path={mdiClose} /></button>
        <h6>{$t('error')}</h6>
        <section>
          {@html br(
            escape(
              $t($syncState instanceof Error ? 'syncing_error_message' : 'save_error_message', {
                error: $syncState.message,
              })
            )
          )}
        </section>
      </div>
    </Dropdown>
  {/if}
</div>

<style>
  .sync-status {
    color: var(--text-color-lighter);
    display: flex;
    align-items: center;
    padding: 0 4px;
    cursor: default;
  }
  .status-icon {
    display: grid;
    place-content: center;
    padding: 0.2em;
  }
  .separator {
    margin: 0 0.25rem;
    width: 1px;
    height: 20px;
    box-shadow: var(--vertical-bevel);
  }
  .error-box {
    position: relative;
    padding: 16px 12px;
    max-width: 300px;
    color: var(--text-color-light);
  }
  .icon.close-btn {
    position: absolute;
    right: 0;
    top: 4px;
    line-height: 1;
  }
  .error-box h6 {
    margin: 0 0 8px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: var(--font-size-xs);
  }
  .error-box section {
    font-size: var(--font-size-sm);
  }
</style>
