<script>
  import { plugins, router, shownModal, t, today } from '@dabble/app';
  import rest from '@dabble/data/rest';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { checkValidity, updateValidity } from '@dabble/toolkit/forms';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiAccount, mdiLoading, mdiLock } from '@mdi/js';
  import { addDays } from 'date-fns';
  import { isCamp, monthAfter, nanowrimo, nanowrimoKey } from '../nanowrimo';

  let locked = true;
  let error;
  let invalid;
  let submitted;
  let identifier = '';
  let password = '';
  let trialDays;
  let extending;
  let subscription;
  let status;
  let plans;
  let billing;

  $: plans = $plugins.plans;
  $: billing = $plugins.billing;
  $: subscription = $plugins.subscription;
  $: trialDays = (plans && plans.defaults && plans.defaults.trial_period_days) || 0;
  $: extending = trialDays && addDays($today, trialDays) < $monthAfter;
  $: status = getSubscriptionStatus(subscription);
  $: specialTrialOffer = !$isCamp && extending && status !== 'active';

  function getSubscriptionStatus(subscription) {
    if (!subscription) return 'canceled';
    if (subscription.status === 'trialing') return 'trialing';
    return 'active';
  }

  async function back() {
    if (sessionStorage.lastUrlBeforeNaNoAuth) {
      await router.navigate(sessionStorage.lastUrlBeforeNaNoAuth, true);
      delete sessionStorage.lastUrlBeforeNaNoAuth;
    } else {
      window.history.back();
    }
  }

  function onInput(event) {
    if (invalid) {
      invalid = updateValidity(invalid, event.target);
    }
  }

  function onSubmit(event) {
    event.preventDefault();
    invalid = checkValidity(event.target);
    if (!invalid) auth();
  }

  async function auth() {
    submitted = true;
    error = null;

    try {
      const result = await rest.POST('nanowrimo', { identifier, password });
      if (result.error) {
        submitted = false;
        error = result.error.message || result.error;
      } else {
        if (result.type !== $nanowrimoKey) {
          result.type = $nanowrimoKey;
        }
        if (!$isCamp) {
          try {
            await plugins.stores.billing.startNaNoWriMoTrial();
          } catch (err) {}
        }
        await nanowrimo.update(result);
        locked = false;
        setTimeout(async () => {
          await back();
          $shownModal = 'nanowrimoSettings';
        }, 500);
      }
    } catch (err) {
      submitted = false;
      error = err.message;
    }
  }
</script>

<div class="screen">
  <div class="box depth-4">
    {#if error}
      <Alert type="danger" dismissible>
        <strong>{$t('error')}</strong>
        {error}
      </Alert>
    {/if}

    <header>
      <h4>Authenticate with NaNoWriMo</h4>
    </header>
    <section class="nano-auth">
      <div class="infographic" class:locked>
        <div class="dabble-logo" />
        <div class="sync">
          <div class="lock" />
          <div class="arrows" />
        </div>
        <div class="nanowrimo-logo" />
      </div>
      <div>
        <p>To give Dabble permission to update your word count, sign in with your:</p>
        <p class="cred-text"><em>NaNoWriMo</em> credentials</p>
        <form on:submit={onSubmit} disabled={submitted} novalidate>
          <div class="form-group{invalid && invalid.identifier ? ' error' : ''}">
            <div class="input-group">
              <label class="input-group-addon icon" for="identifier" use:tooltipTop={'NaNoWriMo Username or Email'}>
                <Icon path={mdiAccount} />
              </label>
              <input
                type="username"
                class="form-control"
                id="identifier"
                name="nanowrimoIdentifier"
                placeholder="NaNoWriMo Username or Email"
                required
                bind:value={identifier}
                autocomplete="off"
                on:input={onInput}
              />
            </div>
            {#if invalid && invalid.identifier}
              <div class="error-message">{invalid.identifier}</div>
            {/if}
          </div>

          <div class="form-group{invalid && invalid.password ? ' error' : ''}">
            <div class="input-group">
              <label class="input-group-addon icon" for="password" use:tooltipTop={'NaNoWriMo Password'}>
                <Icon path={mdiLock} />
              </label>
              <input
                type="password"
                class="form-control"
                id="password"
                name="nanowrimoPassword"
                placeholder="NaNoWriMo Password"
                required
                bind:value={password}
                autocomplete="off"
                on:input={onInput}
              />
            </div>
            {#if invalid && invalid.password}
              <div class="error-message">{invalid.password}</div>
            {/if}
          </div>

          <div class="actions">
            <button class="btn secondary left" type="button" on:click={back} disabled={submitted}>Cancel</button>
            <button class="btn primary" type="submit" disabled={submitted}>
              {#if submitted}<Icon path={mdiLoading} spin />{/if}
              Authenticate</button
            >
          </div>

          {#if specialTrialOffer}
            <div class="trial-offer">
              <p>
                <strong><em>Note:</em></strong> Linking Dabble to participate in NaNoWriMo will
                <strong>
                  {#if status === 'trialing'}
                    extend your trial
                  {:else}
                    start a new trial for you
                  {/if}
                </strong>
                through the end of November.
              </p>
              {#if billing && billing.payment}
                <p>
                  Your subscription will be billed in December with your payment information on file <strong
                    >unless canceled</strong
                  >.
                </p>
              {/if}
            </div>
          {/if}
        </form>
      </div>
    </section>
  </div>
  <div class="register">
    Don’t have a NaNoWriMo account yet? Register for <a href="https://nanowrimo.org/" target="_blank">NaNoWriMo here</a
    >.
  </div>
</div>

<style>
  .screen {
    position: relative;
    max-width: 400px;
    margin: 32px auto;
  }
  @media only screen and (max-width: 416px) {
    .screen {
      width: auto;
      margin: 8px;
    }
  }
  .box {
    padding: 20px;
    border-radius: 2px;
    box-shadow: var(--page-shadow);
    background: var(--page-background);
  }
  .register {
    margin-top: 20px;
    text-align: center;
  }
  header,
  section {
    padding: 16px;
  }
  header {
    border-bottom: 4px solid #f5a503;
  }
  header h4 {
    color: #333;
    margin: 0;
  }
  .infographic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .dabble-logo {
    background: url(../images/Dabble-Color-Icon-Only.png) no-repeat;
    background-size: contain;
    height: 70px;
    width: 70px;
  }
  .nanowrimo-logo {
    background: url(../images/nanowrimo-logo.svg) no-repeat;
    background-size: contain;
    height: 100px;
    width: 100px;
  }
  .sync {
    position: relative;
    margin: 0 40px;
    display: flex;
    flex-direction: column;
  }
  .arrows {
    background: url(../images/arrows.svg) no-repeat;
    background-size: contain;
    height: 48px;
    width: 48px;
  }
  .lock {
    position: absolute;
    left: -12px;
    top: -12px;
    background: url(../images/unlocked.svg) no-repeat;
    height: 24px;
    width: 24px;
  }
  .locked .lock {
    background: url(../images/locked.svg) no-repeat;
  }
  .cred-text {
    font-size: var(--font-size-lg);
    font-weight: bold;
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .nano-auth :global(.loading) {
    color: #ccc;
    margin-left: 4px;
  }
  .trial-offer {
    margin-top: 20px;
    padding: 10px;
    border: 4px solid var(--dabble-yellow);
  }
  .trial-offer p:first-child {
    margin-top: 0;
  }
  .trial-offer p:last-child {
    margin-bottom: 0;
  }
</style>
