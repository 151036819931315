<script>
  import {
    confirm,
    connected,
    connectionSendAfterAuthed,
    dateOptions,
    inform,
    prompt,
    router,
    t,
    userMeta,
  } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import BlankScreen from '@dabble/toolkit/BlankScreen.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { fade } from '@dabble/toolkit/transitions';
  import { mdiCreditCard, mdiLoading } from '@mdi/js';
  import { format } from 'date-fns';
  import { billing, isPaused, subscription, trialing } from '../billing';
  import Invoices from './Invoices.svelte';
  import NoSubscription from './NoSubscription.svelte';
  import Subscribed from './Subscribed.svelte';
  import Trialing from './Trialing.svelte';

  let disabled = false;
  let saving = false;
  $: disabled = !$connected || saving;
  $: payment = $billing.payment;
  $: paused = $isPaused ? $billing.subscription.metadata.is_paused_until : 0;
  $: lang = $userMeta.preferences?.locale || 'en-US';

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'Billing' } }));

  function subscribe(event) {
    const query = event && event.detail && event.detail.trial ? '?trial' : '';
    router.navigate(`/checkout/plans${query}`);
  }

  async function updateSubscription(options) {
    return await save(() => billing.updateSubscription(options));
  }

  async function undoCancellation() {
    if (await updateSubscription()) {
      inform('success', $t('billing_cancellation_undone'));
    }
  }

  async function cancelSubscription(when) {
    const what = $trialing ? 'trial' : 'subscription';
    if (await confirm($t('billing_cancel_title', { what }), $t('billing_cancel_body', { what }))) {
      const result = window.profitwell && (await window.profitwell('on_cancellation'));
      if (!result || result.status !== 'accepted') {
        if (await save(() => billing.cancelSubscription(when))) {
          inform('success', $t('billing_cancellation_cofirmed'));
        }
      }
    }
  }

  async function unpauseSubscription() {
    if (await save(() => billing.unpauseSubscription())) {
      inform('success', $t('billing_pause_undone'));
    }
  }

  async function addCard(paymentMethodId) {
    if (await save(async () => billing.attachPaymentMethod(paymentMethodId))) {
      inform('success', $t('billing_card_added'));
    }
  }

  async function removeCard() {
    if (await save(() => billing.deletePaymentMethod())) {
      inform('success', $t('billing_card_removed'));
    }
  }

  async function applyCoupon(code) {
    if (!code) code = (await prompt($t('prompt_coupon_code'), '', { placeholder: 'COUPON' })).trim();
    if (!code) return;
    saving = true;
    try {
      const coupon = await connectionSendAfterAuthed('getCoupon', code);
      await updateSubscription({ coupon: coupon.id });
    } catch (err) {
      inform('danger', $t(err.message));
    }
    saving = false;
  }

  async function save(callback) {
    saving = true;
    try {
      await callback();
    } catch (err) {
      inform('danger', $t(err.message));
      return false;
    }
    saving = false;
    return true;
  }
</script>

<BlankScreen connected>
  <svelte:fragment slot="title">
    <Icon path={mdiCreditCard} inline />
    {$t('account_screen_billing')}
  </svelte:fragment>

  <div class="section subscription">
    {#if saving}
      <div class="billing-saving" transition:fade>
        <span class="text">{$t('billing_saving')}</span>
        <Icon path={mdiLoading} spin />
      </div>
    {/if}

    {#if $subscription && ($subscription.status !== 'trialing' || payment)}
      <div class="small-section-label span-12">{$t('billing_current_plan')}</div>
    {/if}
    {#if $isPaused}
      <Alert type="warning">
        {@html $t('billing_paused_subscription', { resumeAt: format(paused * 1000, 'PP', dateOptions) })}
      </Alert>
    {/if}
    {#if !$subscription}
      <NoSubscription on:subscribe={subscribe} {disabled} />
    {:else if $trialing}
      <Trialing on:cancel={() => cancelSubscription('now')} on:subscribe={subscribe} {disabled} />
    {:else}
      <Subscribed
        on:subscribe={subscribe}
        on:cancel={cancelSubscription}
        on:unpause={unpauseSubscription}
        on:addCard={event => addCard(event.detail)}
        on:removeCard={removeCard}
        on:coupon={() => applyCoupon()}
        on:undoCancellation={undoCancellation}
        subscription={$subscription}
        {disabled}
      />
    {/if}
  </div>
  <Invoices />
</BlankScreen>

<style>
  .billing-saving {
    position: absolute;
    color: var(--text-color-lighter);
    top: 10px;
    right: 10px;
  }
  .billing-saving .text {
    font-size: var(--font-size-xs);
    text-transform: uppercase;
  }
  .subscription {
    --layout-margin: 0;
    --layout-cross-margin: 0;
    --layout-cross-gutter: 8px;
  }
  .subscription :global(.actions) {
    display: flex;
    flex-direction: column;
  }
  .subscription :global(.actions .btn) {
    width: 100%;
    margin-bottom: 20px;
  }
  .subscription :global(.actions .btn:last-child) {
    margin-bottom: 0;
  }
  .subscription :global(.date) {
    display: inline-block;
    font-weight: 500;
    font-size: var(--font-size-sm);
    padding: 0 6px;
    border-radius: 3px;
    background: var(--gray-lighter);
  }
</style>
