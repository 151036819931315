import { getNow, observe, projectStore } from '@dabble/app';
import { Readable, Unsubscriber, derived, writable } from '@dabble/data/stores/store';
import { addSeconds } from 'date-fns';
import countdown from './timer.mp3';

export interface Sprint {
  start: number;
  end: number;
  wordCount: number;
}

export interface SprintTiming {
  start: number;
  end: number;
  duration: number;
}

export enum Status {
  Pending = 'pending',
  Open = 'open',
  Started = 'started',
  Finished = 'finished',
}

export const StatusText = {
  [Status.Pending]: 'Coming soon',
  [Status.Open]: 'Open',
  [Status.Started]: 'In progress',
  [Status.Finished]: 'Finished',
};

export interface StoreSprintsStore extends Readable<Sprint[]> {
  loaded: Readable<boolean>;
  save(sprint: Sprint): Promise<Sprint>;
  getSprint(id: string): Sprint;
}

export const SECONDS_BEFORE_START = 5;
const SOUND_START = 10;
const audio = new Audio(countdown);
audio.load();
const projectWordCount = derived(projectStore, project => project.counts[project.projectId]?.wordCount || 0);

export function createSprintStore() {
  const { get, set, update, subscribe } = writable<Sprint>(null);
  let unsubscribe: Unsubscriber;

  function start(duration?: number) {
    unsubscribe?.();
    const startingCount = projectWordCount.get();
    if (!duration) duration = parseInt(localStorage.lastDuration) || 15;
    localStorage.lastDuration = duration;
    const start = addSeconds(getNow(), SECONDS_BEFORE_START).getTime();
    const end = start + duration * 60 * 1000;
    unsubscribe = projectWordCount.subscribe(wordCount =>
      update(sprint => ({ ...sprint, wordCount: wordCount - startingCount }))
    );
    set({ start, end, wordCount: 0 });
  }

  function stop() {
    unsubscribe?.();
    unsubscribe = null;
    set(null);
    localStorage.removeItem('lastWordCount');
    lastWordCount.set(null);
  }
  return { start, stop, get, subscribe };
}

export const sprint = createSprintStore();

export const sprintTiming = derived(sprint, sprint => {
  const start = (sprint && sprint.start) || 0;
  const end = (sprint && sprint.end) || 0;
  return { start, end, duration: end - start } as SprintTiming;
});

export const useAudio = writable(!localStorage.noTimerAudio);
export const lastWordCount = writable(parseInt(localStorage.lastWordCount) || null);
export const timeLeft = createTimeLeftStore(sprintTiming);
export const timeUntil = derived([timeLeft, sprintTiming], ([left, { duration }]) => Math.max(0, left - duration));
export const percent = derived([timeLeft, sprintTiming], ([left, { duration }]) => {
  return 1 - Math.min(duration, Math.max(0, left)) / duration;
});
export const secondsLeft = derived(timeLeft, left => Math.ceil(left / 1000));
export const secondsUntil = derived(timeUntil, until => Math.max(0, until / 1000));
export const status = derived([secondsLeft, secondsUntil], ([left, until]) =>
  left <= 0 ? Status.Finished : !until ? Status.Started : Status.Pending
);

useAudio.subscribe(useAudio => (useAudio ? delete localStorage.noTimerAudio : (localStorage.noTimerAudio = '1')));

observe([useAudio, secondsLeft], ([useAudio]) => {
  if (!useAudio) {
    if (!audio.paused) audio.pause();
    return;
  }
  const remaining = getRemainingTime();
  if (remaining && remaining <= SOUND_START && audio.readyState === 4 && audio.paused) {
    audio.currentTime = SOUND_START - remaining - 1;
    audio.play().catch(() => {});
    // The audio can get off sync, so we'll try to fix it
    setTimeout(() => (audio.currentTime = SOUND_START - getRemainingTime() - 1));
  } else if (remaining === 0 && !audio.paused) {
    audio.pause();
    audio.currentTime = 0;
  }
});

function getRemainingTime() {
  return (timeUntil.get() || timeLeft.get()) / 1000;
}

function createTimeLeftStore(timing: Readable<SprintTiming>): Readable<number> {
  return writable(0, set =>
    onAnimationFrame(() => {
      set(Math.max(0, timing.get().end - getNow()));
    })
  );
}

function onAnimationFrame<T extends Array<any>>(method: (...args: T) => any, ...args: T) {
  method(...args);
  let interval = requestAnimationFrame(function onFrame() {
    method(...args);
    interval = requestAnimationFrame(onFrame);
  });
  return () => cancelAnimationFrame(interval);
}
