<script>
  import { dailyGoalStore, projectGoalStore, projectStore, shownModal, t } from '@dabble/app';
  import Fireworks from '@dabble/toolkit/Fireworks.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { number } from '@dabble/toolkit/helpers';
  import { nanowrimo, nanowrimoParticipating } from '../nanowrimo';
  import { writeGoal } from '../rewards';

  const GOAL_CELEBRATION = 'goalCelebration';
  const DAILY_GOAL_CELEBRATION = 'dailyGoalCelebration';

  let forNaNoWriMo;

  $: forNaNoWriMo = $nanowrimoParticipating && $nanowrimo.projectId === $projectStore.projectId;

  function close() {
    $shownModal = null;
  }
</script>

{#if $shownModal === GOAL_CELEBRATION}
  <Modal title={$t('goals_celebrate_you_finished')} on:close={close} nofocus={true}>
    <section>
      <Fireworks />
      {#if forNaNoWriMo}
        <div class="nano-logo winner rotating-border" />
      {/if}
      <h4 style="margin-top:0">{$t('goals_celebrate_congratulations')}</h4>
      {#if !$writeGoal.expired}
        <p>
          {@html $t('goals_celebrate_word_count', {
            wordCount: number($projectGoalStore.end),
          })}
        </p>
        {#if forNaNoWriMo}
          <p>{@html $t('goals_celebrate_nano_discount')}</p>
        {/if}
      {:else}
        <p>{@html $t('reward_finished', { goal: $writeGoal.goal })}</p>
      {/if}
    </section>
    {#if forNaNoWriMo}
      <footer>
        <button class="btn primary outline" on:click={close}>{$t('goals_celebrate_not_yet')}</button>
        <a href="https://nanowrimo.org/" target="_blank" class="btn primary"
          >{$t('goals_celebrate_submit_word_count')}/a>
        </a>
      </footer>
    {:else}
      <footer>
        <button class="btn primary" on:click={close}>{$t('goals_celebrate_yay')}</button>
      </footer>
    {/if}
  </Modal>
{:else if $shownModal === DAILY_GOAL_CELEBRATION}
  <Modal title={$t('goals_celebrate_finished_daily')} on:close={close} nofocus={true}>
    <section>
      <Fireworks />
      <h4 style="margin-top:0">{$t('goals_celebrate_congratulations')}</h4>
      <p>{@html $t('goals_celebrate_finished_daily_wordcount', { wordCount: number($dailyGoalStore.end) || 0 })}</p>
    </section>
    <footer>
      <button class="btn primary" on:click={close}>{$t('goals_celebrate_yay')}</button>
    </footer>
  </Modal>
{/if}

<style>
  .nano-logo {
    float: left;
    background: url(../images/badge.png) no-repeat;
    width: 200px;
    height: 200px;
    background-size: 100% 100%;
    flex: 0 0 auto;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    margin-right: 20px;
  }
  /*.nano-logo.winner {
  background-image: url(../images/badge.png);
}*/
  h4 {
    font-size: 1.25rem;
  }
  footer {
    clear: left;
  }
  .rotating-border {
    position: relative;
  }
  .rotating-border::before,
  .rotating-border::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: -10px;
    box-shadow: inset 0 0 0 2px var(--dabble-teal);
    animation: image-border-rotate 8s linear infinite;
  }
  .rotating-border::before {
    animation-delay: -4s;
  }
  @keyframes image-border-rotate {
    0%,
    100% {
      clip: rect(0px, 220px, 2px, 0px);
    }
    25% {
      clip: rect(0px, 2px, 220px, 0px);
    }
    50% {
      clip: rect(218px, 220px, 220px, 0px);
    }
    75% {
      clip: rect(0px, 220px, 220px, 218px);
    }
  }
</style>
