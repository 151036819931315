import { settings } from '@dabble/app';
import GoalRoutes from './components/GoalRoutes.svelte';
import GoalsCelebrate from './components/GoalsCelebrate.svelte';
import GoalsSettings from './components/GoalsSettings.svelte';
import GoalsSidebar from './components/GoalsSidebar.svelte';
import GoalsSidebarButton from './components/GoalsSidebarButton.svelte';
import NaNoWriMoInvite from './components/NaNoWriMoInvite.svelte';
import NaNoWriMoMenuItem from './components/NaNoWriMoMenuItem.svelte';
import NaNoWriMoSettings from './components/NaNoWriMoSettings.svelte';
import ShareGoalsModal from './components/ShareGoalsModal.svelte';
import './locales';
import './nanowrimo';
import './rewards';
import './sprints';

settings.configure('dabble', {
  sidebar: {
    goals: { GoalsSidebar },
  },
  sidebarButtons: { GoalsSidebarButton },
  globals: { GoalsSettings, GoalsCelebrate, NaNoWriMoInvite, NaNoWriMoSettings, ShareGoalsModal },
  routes: { GoalRoutes },
  plugins: {
    account: {
      menuItems: { NaNoWriMoMenuItem },
    },
  },
});
