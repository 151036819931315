import { authed, observe, plugins, shownModal, userMeta, userStats } from '@dabble/app';
import { UserMetaStore, UserMetaTypeStore, createUserMetaTypeStore } from '@dabble/data/stores/user-meta';
import { UserMeta } from '@dabble/data/types';
import { format } from 'date-fns';

interface WriteGoalForReward extends UserMeta {
  startDate: number;
  wordsAdded: number;
  endDate: number;
  goal: number;
  expired: boolean;
  rewarded: boolean;
  lastCount: number;
}

interface WriteGoalStore extends UserMetaTypeStore<WriteGoalForReward> {
  start: (readme: string, start?: Date, end?: Date) => Promise<WriteGoalForReward>;
}

const oneDay = 24 * 60 * 60 * 1000;
const twoWeeks = 14 * oneDay;

export function createWriteGoalStore(userMeta: UserMetaStore): WriteGoalStore {
  const { get, update, subscribe } = createUserMetaTypeStore<WriteGoalForReward>(userMeta, 'write-for-reward');

  function start(readme: string, start?: Date, end?: Date) {
    const today = new Date();
    return update({
      goal: 1000,
      startDate: start?.getTime() || today.getTime(),
      endDate: end?.getTime() || today.getTime() + twoWeeks,
      rewarded: false,
      wordsAdded: 0,
      totalWords: 0,
      readme,
    });
  }

  return {
    get,
    update,
    subscribe,
    start,
  };
}

export const writeGoal = createWriteGoalStore(userMeta);
plugins.register({ writeGoal });

window.addEventListener('signup', () => {
  setTimeout(() => writeGoal.start('open-write-1000-for-20'), 1000);
});

observe([authed, userStats], ([authed, stats]) => {
  if (!authed) return;
  let goal = writeGoal.get() || ({} as WriteGoalForReward);
  if (!goal?.goal && plugins.stores.trialing.get()) {
    writeGoal.start('open-write-1000-for-20');
    goal = writeGoal.get();
  }
  if (goal?.startDate === undefined) return;
  if (goal.expired || goal.rewarded) return;
  let totalWords = 0;

  let date = goal.startDate;
  const end = goal.endDate;
  while (date <= end) {
    const dateString = format(date, 'yyyy-MM-dd');
    totalWords += (stats?.[dateString]?.change as unknown as number) || 0;
    date += oneDay;
  }
  const updates: Partial<WriteGoalForReward> = { totalWords };
  const diff = totalWords - goal.totalWords;
  if (diff == 1) {
    updates.wordsAdded = goal.wordsAdded + diff;
  }
  writeGoal.update(updates);
});

observe(
  writeGoal,
  runOneAtATime(async ({ goal, expired, rewarded, wordsAdded }) => {
    if (wordsAdded >= goal && !(expired || rewarded)) {
      // update goal to close out card in sidebar
      // & notify whoever needs to know the goal is completed
      // open winner modal and fireworks
      await writeGoal.update({ expired: true });
      shownModal.set('goalCelebration');
      window.dispatchEvent(new CustomEvent('reward-applied'));
      window.dispatchEvent(new CustomEvent('reached-reward-goal'));
    }
  })
);

function runOneAtATime(func: (...args: any[]) => Promise<any>) {
  let running: Promise<any>, nextArgs: any[];
  const run = () => (running = func(...nextArgs).then(() => (running = nextArgs = undefined)));

  return (...args: any[]) => {
    if (running && !nextArgs) running.then(run);
    nextArgs = args;
    if (!running) run();
  };
}
