<script type="ts">
  import { createProject, globalData, selectProject, t, userProjects } from '@dabble/app';
  import { duplicateProject } from '@dabble/data/project-duplicate';
  import rest from '@dabble/data/rest';
  import { currentUser } from '@dabble/plugins/account/auth';
  import { showOnboardingLearnMore } from '../../checklist';
  import { entranceSurvey } from '../../entrance';

  $: genreMap = $globalData.templates?.entrance || {};
  $: genres = $globalData.templates?.list || [];

  async function choose(genre: string) {
    entranceSurvey.update({ ...$entranceSurvey, genre });
    rest.POST('/analytics/survey', {
      email: $currentUser.email,
      attributes: { genre },
    });
    const projects = userProjects.get();
    const templateProject = projects.find(p => p.projectId === genreMap[genre]);
    const userProject = templateProject ? await duplicateProject(templateProject) : (await createProject()).userProject;
    selectProject(userProject.projectId);
    if (templateProject) await userProjects.update(templateProject.projectId, { lastOpened: new Date().getTime() });

    // open Learn More Reward modal
    $showOnboardingLearnMore = true;
  }
</script>

<section>
  <p>{$t('onboarding_survey_text')}</p>
  <div class="genre-list">
    {#each genres as genre}
      <button class="btn large secondary genre-selection" on:click={() => choose(genre)}
        >{$t(`onboarding_genre_${genre}`)}</button
      >
    {/each}
  </div>
</section>

<style>
  .genre-list {
    display: flex;
    flex-direction: column;
  }
  .genre-selection {
    margin-bottom: 8px;
  }
</style>
