<svelte:options accessors={true} />

<script>
  import { plugins, t } from '@dabble/app';
  import { slide } from '@dabble/toolkit/transitions';
  import { onDestroy, onMount } from 'svelte';
  import { createCardStore } from '../stores';

  const newCard = createCardStore();
  export let card = $newCard;
  export let autofocus = false;
  const { theme } = plugins.stores;

  $: card = $newCard;

  onMount(async () => {
    await newCard.mount('#card', {
      style: {
        base: {
          iconColor: $theme === 'dark' ? '#ccc' : undefined,
          color: $theme === 'dark' ? '#aaa' : undefined,
          '::placeholder': {
            color: $theme === 'dark' ? '#aaa' : undefined,
          },
        },
      },
    });
    if (autofocus) newCard.focus();
  });

  onDestroy(() => {
    newCard.destroy();
  });

  export async function save() {
    return await newCard.save();
  }
</script>

<div class="card">
  <div id="card" class="form-control" />
  {#if $newCard.error}
    <div class="alert danger" transition:slide|local={{ duration: 150 }}>{$t($newCard.error)}</div>
  {/if}
</div>

<style>
  div {
    width: 100%;
  }
</style>
