<script>
  import { currentProjectMeta, features, projectMetas } from '@dabble/app';
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiSpellcheck } from '@mdi/js';

  function toggle(field) {
    if (!$features.has('spell_check') && field === 'spellingCheck') {
      features.promptUpgrade('spell_check');
    } else if (!$features.has('grammar') && field === 'grammarCheck') {
      features.promptUpgrade('grammar');
    } else {
      if ($currentProjectMeta) {
        const value =
          field === 'spellingCheck' && $currentProjectMeta.spellingCheck === undefined
            ? false
            : !$currentProjectMeta[field];
        projectMetas.update($currentProjectMeta.id, { [field]: value });
      }
    }
  }
</script>

<button
  class="dropdown-item"
  class:checked={$features.has('spell_check') &&
    ($currentProjectMeta.spellingCheck || $currentProjectMeta.spellingCheck === undefined)}
  on:click|stopPropagation={() => toggle('spellingCheck')}
>
  <Icon path={mdiSpellcheck} />
  {$t('preferences_spellcheck_label')}
</button>
<button
  class="dropdown-item"
  class:checked={$features.has('grammar') && $currentProjectMeta.grammarCheck}
  on:click|stopPropagation={() => toggle('grammarCheck')}
>
  <Icon path={mdiSpellcheck} />
  {$t('preferences_grammarcheck_label')}
</button>
